import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";

import LoadingScreen from "../../components/LoadingScreen";
import Graph from "../../components/Graph";

import { constants } from "../../utils/ranges";
import { useAuth } from "../../utils/auth";
import { sdkWrapperURL } from "../../utils/api-url-list";

import { ReactComponent as BackArrow } from "../../assets/icons/BackArrow.svg";
import { ReactComponent as HeartRate } from "../../assets/icons/HeartRate.svg";
import { ReactComponent as BloodPressure } from "../../assets/icons/BloodPressure.svg";
import { ReactComponent as RespirationRate } from "../../assets/icons/RespirationRate.svg";
import { ReactComponent as OxygenSaturation } from "../../assets/icons/OxygenSaturation.svg";

const BodyVitals = () => {
  const {
    getToken,
    userData: { employee_id, profile_id },
  } = useAuth();

  const [loading, setLoading] = useState(true);
  const [allGraphData, setGraphData] = useState([]);

  const [modalState, setModalState] = useState({
    visible: false,
    title: "",
    desc: "",
  });
  const closeModal = () =>
    setModalState({ visible: false, title: "", desc: "" });

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const dataResp = await fetch(
          sdkWrapperURL("/users/sdk/graph_vitals_history_private"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              employee_id,
              profile_id,
              date_range: "weekly",
            }),
          }
        );
        const dataRespJSON = await dataResp.json();
        if (dataRespJSON?.statusCode?.toString().startsWith("2"))
          setGraphData(dataRespJSON.response.response_graph.reverse());
        else throw new Error(dataRespJSON.message);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="px-6 py-2">
      <div className="flex items-center text-primary">
        <Link className="flex-shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">Body Vitals</h3>
      </div>

      <div className="my-6 space-y-6">
        {loading ? (
          <LoadingScreen style={{ minHeight: "50vh" }} />
        ) : (
          <>
            <Graph
              icon={<HeartRate className="h-5 w-5" />}
              label="Heart Rate"
              chartData={allGraphData.map((item) => ({
                label: item.start_date,
                value:
                  typeof item.heart_rate === "number"
                    ? parseInt(item.heart_rate.toFixed(0), 10)
                    : undefined,
                color: constants["Heart Rate"].ranges.resting.reduce(
                  (p, c) => (item.heart_rate >= c.point ? c.color : p),
                  0
                ),
              }))}
              legend={constants["Heart Rate"].ranges.resting
                .slice(0, -1)
                .map(({ label, color }) => ({ label, color }))}
              unit={constants["Heart Rate"].unit}
              defaultExpanded
              viewInfo={() =>
                setModalState({
                  visible: true,
                  title: "Heart Rate",
                  desc: constants["Heart Rate"].info,
                })
              }
              historyDetail={{
                title: "Heart Rate",
                ranges: constants["Heart Rate"].ranges,
                keys: ["vitals", "heart_rate"],
                unit: constants["Heart Rate"].unit,
              }}
            />
            <Graph
              icon={<BloodPressure className="h-5 w-5" />}
              label="Blood Pressure"
              chartData={[
                allGraphData.map((item) => ({
                  label: item.start_date,
                  value:
                    typeof item.bp_sys === "number"
                      ? parseInt(item.bp_sys.toFixed(0), 10)
                      : undefined,
                  color: constants["Blood Pressure"].ranges[0].reduce(
                    (p, c) => (item.bp_sys > c.point ? c.color : p),
                    0
                  ),
                })),
                allGraphData.map((item) => ({
                  label: item.start_date,
                  value:
                    typeof item.bp_dia === "number"
                      ? parseInt(item.bp_dia.toFixed(0), 10)
                      : undefined,
                  color: constants["Blood Pressure"].ranges[1].reduce(
                    (p, c) => (item.bp_dia > c.point ? c.color : p),
                    0
                  ),
                })),
              ]}
              legend={constants["Blood Pressure"].ranges[0]
                .slice(0, -1)
                .map(({ label, color }) => ({ label, color }))}
              unit={constants["Blood Pressure"].unit}
              defaultExpanded
              viewInfo={() =>
                setModalState({
                  visible: true,
                  title: "Blood Pressure",
                  desc: constants["Blood Pressure"].info,
                })
              }
              historyDetail={{
                title: "Blood Pressure",
                ranges: constants["Blood Pressure"].ranges[0],
                keys: ["vitals", "bp_sys", "vitals", "bp_dia"],
                unit: constants["Blood Pressure"].unit,
              }}
            />
            <Graph
              icon={<RespirationRate className="h-5 w-5" />}
              label="Respiration Rate"
              chartData={allGraphData.map((item) => ({
                label: item.start_date,
                value:
                  typeof item.resp_rate === "number"
                    ? parseInt(item.resp_rate.toFixed(0), 10)
                    : undefined,
                color: constants["Respiration Rate"].ranges.reduce(
                  (p, c) => (item.resp_rate >= c.point ? c.color : p),
                  0
                ),
              }))}
              legend={constants["Respiration Rate"].ranges
                .slice(0, -1)
                .map(({ label, color }) => ({ label, color }))}
              unit={constants["Respiration Rate"].unit}
              defaultExpanded
              viewInfo={() =>
                setModalState({
                  visible: true,
                  title: "Respiration Rate",
                  desc: constants["Respiration Rate"].info,
                })
              }
              historyDetail={{
                title: "Respiration Rate",
                ranges: constants["Respiration Rate"].ranges,
                keys: ["vitals", "resp_rate"],
                unit: constants["Respiration Rate"].unit,
              }}
            />
            <Graph
              icon={<OxygenSaturation className="h-5 w-5" />}
              label="Oxygen Saturation"
              chartData={allGraphData.map((item) => ({
                label: item.start_date,
                value:
                  typeof item.oxy_sat_prcnt === "number"
                    ? parseInt(item.oxy_sat_prcnt.toFixed(0), 10)
                    : undefined,
                color: constants["Oxygen Saturation"].ranges.reduce(
                  (p, c) => (item.oxy_sat_prcnt >= c.point ? c.color : p),
                  0
                ),
              }))}
              legend={constants["Oxygen Saturation"].ranges
                .slice(0, -1)
                .map(({ label, color }) => ({ label, color }))}
              unit={constants["Oxygen Saturation"].unit}
              defaultExpanded
              viewInfo={() =>
                setModalState({
                  visible: true,
                  title: "Oxygen Saturation",
                  desc: constants["Oxygen Saturation"].info,
                })
              }
              historyDetail={{
                title: "Oxygen Saturation",
                ranges: constants["Oxygen Saturation"].ranges,
                keys: ["vitals", "oxy_sat_prcnt"],
                unit: constants["Oxygen Saturation"].unit,
              }}
            />
            <Transition show={modalState.visible} as={Fragment}>
              <Dialog as={Fragment} onClose={closeModal}>
                <Transition.Child
                  className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
                  enter="duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="duration-300 delay-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Transition.Child
                    as={Fragment}
                    enter="delay-150 duration-200"
                    enterFrom="opacity-0 scale-50"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100"
                    leaveFrom="opacity-50 scale-50"
                    leaveTo="opacity-0 scale-0"
                  >
                    <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                      <Dialog.Title className="text-primary text-xs font-medium">
                        {modalState.title}
                      </Dialog.Title>
                      <Dialog.Description className="mt-2 text-primary/70 text-xxs">
                        {modalState.desc}
                      </Dialog.Description>
                      <button
                        className="mt-4 px-4 py-2 rounded-full bg-secondary text-white text-xs font-medium"
                        type="button"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </Dialog.Panel>
                  </Transition.Child>
                </Transition.Child>
              </Dialog>
            </Transition>
          </>
        )}
      </div>
    </section>
  );
};

export default BodyVitals;
