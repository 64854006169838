import { ReactComponent as CheckMark } from "../assets/icons/Check.svg";

const ListItem = ({ children }) => (
  <li class="flex items-start justify-start space-x-4">
    <CheckMark className="flex-shrink-0 mt-1 h-4 w-4 text-secondary" />
    <p className="text-darkgray text-sm">{children}</p>
  </li>
);

const PrivacyPolicy = () => (
  <div className="p-8 bg-white text-justify">
    <h1 className="mb-8 text-secondary text-2xl text-center font-semibold">
      Privacy Policy
    </h1>

    <h3 className="mt-6 text-primary font-medium">
      We live and die by our rule: you always remain the sole owner of your
      data.
    </h3>
    <p className="mt-3 text-darkgray text-sm">
      The present Privacy Policy is supplied electronically for the registration
      of the application. A paper format is available upon request at
      connect@careplix.com or click on the print option on the right side of the
      page.
    </p>

    <h3 className="mt-6 text-primary font-medium">
      What information do we collect about you?
    </h3>
    <ul className="mt-3 space-y-4">
      <ListItem>
        When you start using the Careplix Vitals, we will ask you to create an
        account to allow secure storage of your data. In this respect, we
        collect limited general personal details such as your name email
        address, and demographic information like your age, gender, and country.
      </ListItem>
      <ListItem>
        By using our Services, you will provide us with your health data and
        health-related data (such as your measurements). Otherwise, we cannot do
        a body scan: Given its sensitive nature, additional technical and
        organizational measures are taken to safeguard your information.
      </ListItem>
      <ListItem>
        We collect information about the device you use to access the Careplix
        Vitals App or Careplix Vitals Dashboard to assure quality.
      </ListItem>
    </ul>

    <h3 className="mt-6 text-primary font-medium">
      Why do we collect this information?
    </h3>
    <ul className="mt-3 space-y-4">
      <ListItem>
        To assist you in your health journey to timely detect irregular heart
        rhythm and provide you comprehensive vital support.
      </ListItem>
      <ListItem>To provide customer support.</ListItem>
      <ListItem>
        To improve our Services and make sure you can make optimal use of
        Careplix Vitals.
      </ListItem>
      <ListItem>
        To contact you and keep you up to date about our Services.
      </ListItem>
      <ListItem>
        To send you questionnaires or invite you to provide a review of your
        experiences with our service(s). We also may get in touch with you
        regarding feedback, inquiries, and complaints you made regarding our
        offered Services.
      </ListItem>
      <ListItem>
        In the event, that a project is funded by your employer, provide your
        employer with insights regarding the health of its employees. In case
        you gave explicit consent, we will provide the occupational physician of
        your employer's Health and Safety department with an analysis of your
        measurements. Otherwise only aggregated data (meaning anonymized data)
        shall be shared.
      </ListItem>
      <ListItem>
        For scientific and research purposes, we will use and/or share only
        pseudonymized Personal Data or anonymized data.
      </ListItem>
    </ul>

    <h3 className="mt-6 text-primary font-medium">
      Who has access to your data?
    </h3>
    <ul className="mt-3 space-y-4">
      <ListItem>
        Your Personal Data will never be sold or made available to other
        parties. Remember our mantra? You always remain the sole owner of your
        data.
      </ListItem>
      <ListItem>
        Only a select number of people of the trustworthy Careplix Vitals team
        have access to your information.
      </ListItem>
      <ListItem>
        We do make use of external suppliers and service providers, such as web
        hosting services. These parties are legally obliged to always ensure
        your privacy. They can only process data in accordance with our
        purposes.
      </ListItem>
      <ListItem>
        We will only share your pseudonymized Personal Data (for scientific and
        research purposes) or anonymized data.
      </ListItem>
    </ul>

    <h3 className="mt-6 text-primary font-medium">What do you control?</h3>
    <p className="mt-3 text-darkgray text-sm">
      It is your data, so you are in control
    </p>

    <h3 className="mt-6 text-primary font-medium">
      Want to modify your Personal Data? You can do so yourself in the Careplix
      Vitals App.
    </h3>
    <p className="mt-3 text-darkgray text-sm">
      Want to know what information we collect from you? Almost all the data is
      visible in your dashboard. Or you can request a copy of all the
      information we collected about you by sending an email to
      connect@careplix.com
    </p>

    <h3 className="mt-6 text-primary font-medium">
      How do we protect your data?
    </h3>
    <p className="mt-3 text-darkgray text-sm">
      A multitude of safety measures has been put in place on both a technical
      and organizational level. We implemented internal procedures to ensure the
      confidentiality of our IT infrastructure and to make sure it is managed
      responsibly.
    </p>

    <h3 className="mt-6 text-primary font-medium">
      Want to know more? Scroll down to read our Privacy Policy in its entirety.
    </h3>
    <p className="mt-3 text-darkgray text-sm">
      We CareNow Healthcare Pvt Ltd., are registered at 71D/1 Swinhoe Lane 2nd
      Floor Kolkata West Bengal - 700042 hereinafter referred to as Careplix
      Vitals.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      At Careplix Vitals, we value your trust &amp; respect your privacy. This
      Privacy Policy provides you with details about the way your data is
      collected, stored &amp; used by us. You are advised to read this Privacy
      Policy carefully.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      By downloading and using the Careplix Vitals application/ website/WAP site
      you expressly give us consent to use &amp; disclose your personal
      information in accordance with this Privacy Policy. If you do not agree to
      the terms of the policy, please do not use or access Careplix Vitals.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      <b>Note:</b> Our privacy policy may change at any time without prior
      notification. To make sure that you are aware of any changes, kindly
      review the policy periodically. This Privacy Policy shall apply uniformly
      to Careplix Vitals applications, desktop website &amp; mobile WAP site.
    </p>

    <h3 className="mt-6 text-primary font-medium">General</h3>
    <p className="mt-3 text-darkgray text-sm">
      We will not sell, share, or rent your personal information to any 3rd
      party or use your email address/mobile number for unsolicited emails
      and/or SMS. Any emails and/or SMS sent by Careplix Vitals will only be in
      connection with the provision of agreed services &amp; products and this
      Privacy Policy. Periodically, we may reveal general statistical
      information about Careplix Vitals &amp; its users, such as the number of
      visitors, number and type of goods and services purchased, etc. We reserve
      the right to communicate your personal information to any third party that
      makes a legally-compliant request for its disclosure.
    </p>

    <h3 className="mt-6 text-primary font-medium">Personal Information</h3>
    <p className="mt-3 text-darkgray text-sm">
      Personal Information means and includes all information that can be linked
      to a specific individual or to identify any individual, such as name,
      address, email ID, and any details that may have been voluntarily provided
      by the user in connection with availing any of the services on Careplix
      Vitals.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      When you browse through Careplix Vitals, we may collect information
      regarding the mobile/ tab device details, domain, and host from which you
      access the internet, the Internet Protocol [IP] address of the computer or
      Internet service provider [ISP] you are using, and anonymous site
      statistical data.
    </p>

    <h3 className="mt-6 text-primary font-medium">
      Use of Personal Information
    </h3>
    <p className="mt-3 text-darkgray text-sm">
      We use personal information to provide you with services &amp; products
      you explicitly requested, to resolve disputes, troubleshoot concerns, help
      promote safe services, collect money, measure consumer interest in our
      services, inform you about offers, products, services, updates, customize
      your experience, detect &amp; protect us against error, fraud and other
      criminal activity, enforce our terms and conditions, etc.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      We also use your contact information to send you offers based on your
      previous orders and interests.
    </p>
    <p className="mt-3 text-darkgray text-sm">
      We may occasionally ask you to complete optional online surveys. These
      surveys may ask you for contact information and demographic information
      (like zip code, age, gender, etc.). We use this data to customize your
      experience at Careplix Vitals, providing you with content that we think
      you might be interested in and displaying the content according to your
      preferences.
    </p>

    <h3 className="mt-6 text-primary font-medium">Cookies</h3>
    <p className="mt-3 text-darkgray text-sm">
      A "cookie" is a small piece of information stored by a web server on a web
      browser so it can be later read back from that browser. Careplix Vitals
      uses cookie and tracking technology depending on the features offered. No
      personal information will be collected via cookies and other tracking
      technology; however, if you previously provided personally identifiable
      information, cookies may be tied to such information. Aggregate cookie and
      tracking information may be shared with third parties.
    </p>

    <h3 className="mt-6 text-primary font-medium">Links to Other Sites</h3>
    <p className="mt-3 text-darkgray text-sm">
      Our site links to other websites that may collect personally identifiable
      information about you. Careplix Vitals is not responsible for the privacy
      practices or the content of those linked websites.
    </p>

    <h3 className="mt-6 text-primary font-medium">Security</h3>
    <p className="mt-3 text-darkgray text-sm">
      Careplix Vitals has stringent security measures in place to protect the
      loss, misuse, and alteration of the information under our control.
      Whenever you change or access your account information, we offer the use
      of a secure server. Once your information is in our possession we adhere
      to strict security guidelines, protecting it against unauthorized access.
    </p>

    <h3 className="mt-6 text-primary font-medium">Consent</h3>
    <p className="mt-3 text-darkgray text-sm">
      By using Careplix Vitals and/or by providing your information, you consent
      to the collection and use of the information you disclose on Careplix
      Vitals in accordance with this Privacy Policy, including but not limited
      to your consent for sharing your information as per this privacy policy.
    </p>
  </div>
);

export default PrivacyPolicy;
