import { Link } from "react-router-dom";

import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import FaceScanTutorialThumbnail from "../assets/videos/tutorial/face/thumbnail.png";
import FaceScanTutorialVideo from "../assets/videos/tutorial/face/video.mp4";
import FingerScanTutorialThumbnail from "../assets/videos/tutorial/finger/thumbnail.png";
import FingerScanTutorialVideo from "../assets/videos/tutorial/finger/video.mp4";

const Tutorial = () => (
  <section className="px-6 py-2">
    <div className="flex items-center text-primary">
      <Link className="flex-shrink-0 p-1 pr-2.5" to={-1}>
        <BackArrow className="h-2.5 w-2.5" />
      </Link>
      <h3 className="text-sm font-medium">Tutorial</h3>
    </div>

    <div className="my-6 space-y-6">
      <div className="p-4 rounded-xl bg-secondary/10">
        <video
          className="rounded-lg"
          src={FaceScanTutorialVideo}
          poster={FaceScanTutorialThumbnail}
          controls
          controlsList="nodownload nofullscreen noplaybackrate noremoteplayback"
          playsInline
          disablePictureInPicture
          disableRemotePlayback
          onEnded={(e) => {
            e.target.pause();
            e.target.currentTime = 0;
            e.target.load();
          }}
        />
        <h3 className="mt-4 text-xs text-primary font-medium">
          How to do a <span className="text-secondary">face</span> scan?
        </h3>
        <p className="mt-1 text-xxs text-primary/70">
          Learn how a face scan works. It's as easy as taking a selfie.
        </p>
      </div>
      <div className="p-4 rounded-xl bg-secondary/10">
        <video
          className="rounded-lg"
          src={FingerScanTutorialVideo}
          poster={FingerScanTutorialThumbnail}
          controls
          controlsList="nodownload nofullscreen noplaybackrate noremoteplayback"
          playsInline
          disablePictureInPicture
          disableRemotePlayback
          onEnded={(e) => {
            e.target.pause();
            e.target.currentTime = 0;
            e.target.load();
          }}
        />
        <h3 className="mt-4 text-xs text-primary font-medium">
          How to do a <span className="text-secondary">finger</span> scan?
        </h3>
        <p className="mt-1 text-xxs text-primary/70">
          Learn how to place your finger on the rear camera and the flashlight.
        </p>
      </div>
    </div>
  </section>
);

export default Tutorial;
