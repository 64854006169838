import { Link } from "react-router-dom";

import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import { ReactComponent as Arrow } from "../assets/icons/Arrow.svg";
import FaceScan from "../assets/images/FaceScan.png";
import FingerScan from "../assets/images/FingerScan.png";

const SelectOption = () => {
  return (
    <section className="px-6 py-2">
      <div className="flex items-center text-primary">
        <Link className="flex-shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">Select Scan Option</h3>
      </div>

      <div className="my-6 space-y-6">
        <Link
          className="p-6 rounded-xl flex space-x-6 bg-secondary/10"
          to="/scan-instructions"
          state={{ scanType: "face" }}
        >
          <img
            className="flex-shrink-0 h-12 w-12"
            src={FaceScan}
            alt="face-scan"
          />
          <div>
            <h4 className="text-primary text-sm font-medium">Face Scan</h4>
            <p className="mt-1 text-darkgray text-xxs">
              60-seconds contactless vital scan using your smartphone's front
              camera. It is as simple as taking a selfie.
            </p>
            <div className="inline-flex items-center space-x-1 rounded-full mt-2 px-3 py-1 bg-secondary text-white">
              <span className="flex-shrink-0 text-xxs">Start Scan</span>
              <Arrow className="flex-shrink-0 h-2.5 w-2.5" />
            </div>
          </div>
        </Link>
        <Link
          className="p-6 rounded-xl flex space-x-6 bg-secondary/10"
          to="/scan-instructions"
          state={{ scanType: "finger" }}
        >
          <img
            className="flex-shrink-0 h-12 w-12"
            src={FingerScan}
            alt="finger-scan"
          />
          <div>
            <h4 className="text-primary text-sm font-medium">Finger Scan</h4>
            <p className="mt-1 text-darkgray text-xxs">
              Place your finger on the rear camera of your smartphone to scan
              your vital signs in 60 seconds.
            </p>
            <div className="inline-flex items-center space-x-1 rounded-full mt-2 px-3 py-1 bg-secondary text-white">
              <span className="flex-shrink-0 text-xxs">Start Scan</span>
              <Arrow className="flex-shrink-0 h-2.5 w-2.5" />
            </div>
          </div>
        </Link>
      </div>

      <div className="mt-12 mb-6 p-6 rounded-xl bg-secondary text-white space-y-1.5">
        <h3 className="text-xs font-medium">Disclaimer</h3>
        <p className="text-xxs text-justify">
          For Investigational Use Only. Careplix is not a substitute for the
          clinical judgment of a healthcare professional. Careplix is intended
          to improve your awareness of general wellness. Careplix does not
          diagnose, treat, mitigate or prevent any disease, symptom, disorder or
          abnormal physical state. Consult with a health care professional or
          emergency services if you believe you may have a medical issue.
        </p>
      </div>
    </section>
  );
};

export default SelectOption;
