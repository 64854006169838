import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import TextInput from "../components/TextInput";
import GenderSelect from "../components/GenderSelect";
import DOBSelect from "../components/DOBSelect";

import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";

import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import { ReactComponent as Loader } from "../assets/icons/Loader.svg";

const MyAccount = () => {
  const { getToken, userData } = useAuth();

  const emailRef = useRef(null);
  const heightRef = useRef(null);
  const weightRef = useRef(null);

  const [email, setEmail] = useState(userData.email);
  const [gender, setGender] = useState(userData.gender);
  const [dob, setDOB] = useState(userData.dob);
  const [height, setHeight] = useState(userData.height?.toString?.());
  const [weight, setWeight] = useState(userData.weight?.toString?.());

  const [emailError, setEmailError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [dobError, setDOBError] = useState("");
  const [heightError, setHeightError] = useState("");
  const [weightError, setWeightError] = useState("");

  const [isLoading, setLoading] = useState(false);

  const updateProfile = async () => {
    try {
      setLoading(true);
      setEmailError("");
      setGenderError("");
      setDOBError("");
      setHeightError("");
      setWeightError("");
      let error = "";
      const trim_weight = weight.trim();
      if (/^\d{1,3}(\.\d{1,2})?$/.test(trim_weight)) {
        if (trim_weight < 20 || trim_weight > 220) {
          setWeightError("Weight must be between 20 and 220 kgs.");
          error = "weight";
        }
      } else {
        setWeightError("Please enter valid Weight.");
        error = "weight";
      }
      setWeight(trim_weight);
      const trim_height = height.trim();
      if (/^\d{1,3}(\.\d{1,2})?$/.test(trim_height)) {
        if (trim_height < 30 || trim_height > 250) {
          setHeightError("Height must be between 30 and 250 cms.");
          error = "height";
        }
      } else {
        setHeightError("Please enter valid Height.");
        error = "height";
      }
      setHeight(trim_height);
      if (dob.length === 0) {
        setDOBError("Please select your Date of Birth.");
        error = "dob";
      }
      if (gender.length === 0) {
        setGenderError("Please select your Gender.");
        error = "gender";
      }
      const trim_email = email.toLowerCase().trim();
      if (
        trim_email.length > 0 &&
        !/^[a-z0-9]+([._-]*[a-z0-9])+[@](\w+[.])+\w+$/.test(trim_email)
      ) {
        setEmailError(
          "Invalid email address. Please enter a correct email address and try again."
        );
        error = "email";
      }
      setEmail(trim_email);
      if (error.length > 0) {
        switch (error) {
          case "email":
            emailRef.current?.focus?.();
            break;
          case "height":
            heightRef.current?.focus?.();
            break;
          case "weight":
            weightRef.current?.focus?.();
            break;
          default:
            break;
        }
      } else {
        const token = await getToken();
        const saveResp = await fetch(sdkWrapperURL("/users/profile/update"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            is_terms_accepted: true,
            profile_id: userData.profile_id,
            email: email,
            gender: gender,
            dob: dob,
            height: height,
            weight: weight,
          }),
        });
        const saveRespJSON = await saveResp.json();
        if (saveRespJSON?.statusCode?.toString().startsWith("2"))
          window.location.reload(true);
        else if (
          saveRespJSON?.message === "Email already exist in our database"
        )
          setEmailError("This Email is already used.");
        else throw new Error(saveRespJSON?.message ?? "Error in Saving");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="px-6 py-2">
      <div className="flex items-center text-primary">
        <Link className="flex-shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">My Account</h3>
      </div>

      <form
        className="my-6"
        onSubmit={async (e) => {
          e.preventDefault();
          if (!isLoading) await updateProfile();
        }}
        noValidate
      >
        <div className="space-y-4">
          <TextInput
            type="text"
            id="name"
            label="Name"
            disabled
            value={userData.name}
          />
          <TextInput
            type="text"
            id="relation"
            label="Relation"
            disabled
            value={userData.relation.toLowerCase()}
            style={{ textTransform: "capitalize" }}
          />
          <TextInput
            inputRef={emailRef}
            type="email"
            id="email"
            label="Email"
            placeholder="Enter your Email"
            autoComplete="email"
            inputMode="email"
            onChangeText={setEmail}
            value={email}
            error={emailError}
          />
          <GenderSelect
            label="Gender"
            required
            onSelect={(selectedGender) => {
              setGenderError("");
              setGender(selectedGender);
            }}
            value={gender}
            error={genderError}
          />
          <DOBSelect
            id="dob"
            label="Date of Birth"
            placeholder="Select your Date of Birth"
            required
            onSelect={setDOB}
            value={dob}
            error={dobError}
          />
          <TextInput
            inputRef={heightRef}
            type="text"
            id="height"
            label="Height (cms)"
            placeholder="Enter your Height in cms"
            inputMode="decimal"
            required
            onChangeText={setHeight}
            value={height}
            error={heightError}
          />
          <TextInput
            inputRef={weightRef}
            type="text"
            id="weight"
            label="Weight (kgs)"
            placeholder="Enter your Weight in kgs"
            inputMode="decimal"
            required
            onChangeText={setWeight}
            value={weight}
            error={weightError}
          />
        </div>
        <button
          className="mt-8 mx-auto w-40 flex items-center justify-center space-x-2 rounded-full px-4 py-2 bg-secondary disabled:bg-darkgray text-white"
          type="submit"
          disabled={isLoading}
        >
          <span className="text-xs font-medium">Update</span>
          {isLoading && <Loader className="flex-shrink-0 h-4 w-4" />}
        </button>
      </form>
    </section>
  );
};

export default MyAccount;
