import { Fragment, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, Listbox, Transition } from "@headlessui/react";

import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";

import TextInput from "../components/TextInput";
import GenderSelect from "../components/GenderSelect";
import DOBSelect from "../components/DOBSelect";

import { ReactComponent as Loader } from "../assets/icons/Loader.svg";
import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";

const BasicDetails = () => {
  const { getToken, profileList, logout } = useAuth();

  const [isModalOpen, setModalOpen] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const relationList = useMemo(() => {
    const baseList = [
      "Mother",
      "Father",
      "Spouse",
      "Sister",
      "Brother",
      "Other",
    ];
    if (profileList.findIndex((p) => p.relation === "SELF") === -1)
      return ["Self", ...baseList];
    else return baseList;
  }, [profileList]);

  const memberIDRef = useRef(null);
  const newRelationRef = useRef(null);
  const emailRef = useRef(null);
  const heightRef = useRef(null);
  const weightRef = useRef(null);

  const [memberID, setMemberID] = useState("");
  const [name, setName] = useState("");
  const [relation, setRelation] = useState(relationList[0]);
  const [newRelation, setNewRelation] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");

  const [memberIDError, setMemberIDError] = useState("");
  const [newRelationError, setNewRelationError] = useState("");
  const [emailExistsList, setEmailExistsList] = useState(new Set());
  const [emailError, setEmailError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [dobError, setDOBError] = useState("");
  const [heightError, setHeightError] = useState("");
  const [weightError, setWeightError] = useState("");

  const [isAccepted, setAccepted] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const checkMemberID = async () => {
    try {
      setLoading(true);
      setMemberIDError("");
      if (!(memberID?.length > 0)) throw new Error("Member ID is required.");
      const token = await getToken();
      const memberIDResp = await fetch(
        sdkWrapperURL("/users/member/check_profile"),
        {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({ member_id: memberID }),
        }
      );
      const memberIDRespJSON = await memberIDResp.json();
      if (memberIDRespJSON?.statusCode?.toString().startsWith("2")) {
        setName(memberIDRespJSON.member_info?.name ?? "");
        setPageNo(2);
      } else
        throw new Error(
          memberIDRespJSON?.message ?? "Error in Validating the Member ID"
        );
    } catch (err) {
      setMemberIDError(err.message);
      memberIDRef.current?.focus?.();
    } finally {
      setLoading(false);
    }
  };

  const proceed = () => {
    try {
      setLoading(true);
      setNewRelationError("");
      setEmailError("");
      let error = "";
      const trim_email = email.toLowerCase().trim();
      if (
        trim_email.length > 0 &&
        !/^[a-z0-9]+([._-]*[a-z0-9])+[@](\w+[.])+\w+$/.test(trim_email)
      ) {
        setEmailError(
          "Invalid email address. Please enter a correct email address and try again."
        );
        error = "email";
      } else if (emailExistsList.has(email)) {
        setEmailError("This Email is already used.");
        error = "email";
      }
      setEmail(trim_email);
      if (relation === "Other") {
        const trim_new_relation = newRelation.trim();
        if (trim_new_relation.length <= 0) {
          setNewRelationError("Please specify your Relation");
          error = "new-relation";
        } else if (
          relationList[0] !== "Self" &&
          trim_new_relation.toUpperCase() === "SELF"
        ) {
          setNewRelationError("This Relation cannot be used Multiple times.");
          error = "new-relation";
        }
        setNewRelation(trim_new_relation);
      }
      if (error.length > 0) {
        switch (error) {
          case "new-relation":
            newRelationRef.current?.focus?.();
            break;
          case "email":
            emailRef.current?.focus?.();
            break;
          default:
            break;
        }
      } else setPageNo(3);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const saveDetails = async () => {
    try {
      setLoading(true);
      setGenderError("");
      setDOBError("");
      setHeightError("");
      setWeightError("");
      let error = "";
      const trim_weight = weight.trim();
      if (/^\d{1,3}(\.\d{1,2})?$/.test(trim_weight)) {
        if (trim_weight < 20 || trim_weight > 220) {
          setWeightError("Weight must be between 20 and 220 kgs.");
          error = "weight";
        }
      } else {
        setWeightError("Please enter valid Weight.");
        error = "weight";
      }
      setWeight(trim_weight);
      const trim_height = height.trim();
      if (/^\d{1,3}(\.\d{1,2})?$/.test(trim_height)) {
        if (trim_height < 30 || trim_height > 250) {
          setHeightError("Height must be between 30 and 250 cms.");
          error = "height";
        }
      } else {
        setHeightError("Please enter valid Height.");
        error = "height";
      }
      setHeight(trim_height);
      if (dob.length === 0) {
        setDOBError("Please select your Date of Birth.");
        error = "dob";
      }
      if (gender.length === 0) {
        setGenderError("Please select your Gender.");
        error = "gender";
      }
      if (error.length > 0) {
        switch (error) {
          case "height":
            heightRef.current?.focus?.();
            break;
          case "weight":
            weightRef.current?.focus?.();
            break;
          default:
            break;
        }
      } else if (!isAccepted) {
        setModalOpen(true);
      } else {
        const token = await getToken();
        const saveResp = await fetch(
          sdkWrapperURL("/users/member/add_profile"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              is_terms_accepted: isAccepted,
              email: email,
              dob: dob,
              gender: gender,
              height: height,
              weight: weight,
              member_id: memberID,
              relation: relation.toUpperCase(),
              other_relation:
                relation === "Other" ? newRelation.toUpperCase() : undefined,
            }),
          }
        );
        const saveRespJSON = await saveResp.json();
        if (saveRespJSON?.statusCode?.toString().startsWith("2"))
          window.location.replace("/");
        else if (saveRespJSON?.message === "Please enter correct relation") {
          setNewRelationError(saveRespJSON?.message);
          setPageNo(2);
        } else if (
          saveRespJSON?.message === "Email already exist in our database"
        ) {
          setEmailExistsList((emailList) => new Set(emailList.add(email)));
          setEmailError("This Email is already used.");
          setPageNo(2);
        } else throw new Error(saveRespJSON?.message ?? "Error in Saving");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="min-h-screen max-w-sm mx-auto p-6 relative bg-white">
      <h2 className="mt-6 mb-4 text-secondary text-sm font-semibold">
        {pageNo === 1
          ? "Enter Member ID"
          : pageNo === 2
          ? "We need few details,"
          : "Almost done,"}
      </h2>

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (!isLoading) {
            if (pageNo === 1) checkMemberID();
            else if (pageNo === 2) proceed();
            else await saveDetails();
          }
        }}
        noValidate
      >
        {pageNo === 1 ? (
          <TextInput
            inputRef={memberIDRef}
            type="text"
            id="member-id"
            label="Member ID"
            placeholder="Enter your Member ID"
            autoComplete="off"
            required
            onChangeText={setMemberID}
            value={memberID}
            error={memberIDError}
          />
        ) : pageNo === 2 ? (
          <div className="space-y-4">
            <TextInput
              type="text"
              id="name"
              label="Name"
              disabled
              value={name}
            />
            <div>
              <label htmlFor="relation" className="text-primary/60 text-xs">
                Relation
              </label>
              <Listbox
                value={relation}
                onChange={setRelation}
                as="div"
                className="relative"
              >
                <Listbox.Button className="w-full min-h-[2.325rem] mt-2 px-4 py-2 flex items-center justify-between rounded-lg border border-lightgray/20 outline-none focus:border-secondary text-primary">
                  {({ open }) => (
                    <>
                      <span className="text-xs">{relation}</span>
                      <Chevron
                        className={`shrink-0 h-3 w-3 transition-transform duration-150 ease-in-out ${
                          open ? "rotate-180" : ""
                        }`}
                      />
                    </>
                  )}
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Listbox.Options
                    as="ul"
                    className="absolute inset-x-0 mt-1 py-2 origin-top outline-none shadow-lg rounded-lg border border-lightgray/20 bg-[#fafafa] z-50"
                  >
                    {relationList.map((rel) => (
                      <Listbox.Option
                        as="li"
                        key={rel}
                        className={({ active }) =>
                          `px-4 py-2 ${
                            active ? "bg-[#383838] text-white" : "text-primary"
                          } text-xs`
                        }
                        value={rel}
                      >
                        {rel}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </Listbox>
            </div>
            {relation === "Other" && (
              <TextInput
                inputRef={newRelationRef}
                type="text"
                id="new-relation"
                label="Add Relation"
                placeholder="Enter the Relation"
                autoComplete="off"
                required
                maxLength={15}
                onChangeText={setNewRelation}
                value={newRelation}
                error={newRelationError}
              />
            )}
            <TextInput
              inputRef={emailRef}
              type="email"
              id="email"
              label="Email"
              placeholder="Enter your Email"
              autoComplete="email"
              inputMode="email"
              onChangeText={setEmail}
              value={email}
              error={emailError}
            />
          </div>
        ) : (
          <div className="space-y-4">
            <GenderSelect
              label="Gender"
              required
              onSelect={(selectedGender) => {
                setGenderError("");
                setGender(selectedGender);
              }}
              value={gender}
              error={genderError}
            />
            <DOBSelect
              id="dob"
              label="Date of Birth"
              placeholder="Select your Date of Birth"
              required
              onSelect={setDOB}
              value={dob}
              error={dobError}
            />
            <TextInput
              inputRef={heightRef}
              type="text"
              id="height"
              label="Height (cms)"
              placeholder="Enter your Height in cms"
              inputMode="decimal"
              required
              onChangeText={setHeight}
              value={height}
              error={heightError}
            />
            <TextInput
              inputRef={weightRef}
              type="text"
              id="weight"
              label="Weight (kgs)"
              placeholder="Enter your Weight in kgs"
              inputMode="decimal"
              required
              onChangeText={setWeight}
              value={weight}
              error={weightError}
            />
            <div className="flex items-center px-1 space-x-2">
              <input
                type="checkbox"
                name="terms-n-policy"
                id="terms-n-policy"
                required
                value={isAccepted}
                checked={isAccepted}
                onChange={(e) => setAccepted(e.target.checked)}
              />
              <label
                htmlFor="terms-n-policy"
                className="text-lightgray text-xxs"
              >
                I accept the&nbsp;
                <Link
                  className={`text-primary ${
                    isLoading ? "pointer-events-none" : ""
                  }`}
                  to="/terms-of-use"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms of use
                </Link>
                &nbsp;&amp;&nbsp;
                <Link
                  className={`text-primary ${
                    isLoading ? "pointer-events-none" : ""
                  }`}
                  to="/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  privacy policy
                </Link>
              </label>
            </div>
          </div>
        )}

        <button
          className="mt-8 w-full flex items-center justify-center space-x-2 rounded-full px-4 py-2 bg-secondary disabled:bg-darkgray text-white"
          type="submit"
          disabled={isLoading}
        >
          <span className="text-sm font-medium">
            {pageNo === 1 ? "Verify" : pageNo === 2 ? "Next" : "Proceed"}
          </span>
          {isLoading && <Loader className="flex-shrink-0 h-4 w-4" />}
        </button>

        <button
          className="block mx-auto mt-4 text-primary text-xs text-center font-medium"
          type="button"
          onClick={pageNo === 1 ? logout : () => setPageNo((p) => p - 1)}
          disabled={isLoading}
        >
          {pageNo === 1 ? "Logout" : "Back"}
        </button>
      </form>
      <Transition show={isModalOpen} as={Fragment}>
        <Dialog as={Fragment} onClose={() => setModalOpen(false)}>
          <Transition.Child
            className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-100"
              leaveFrom="opacity-50 scale-50"
              leaveTo="opacity-0 scale-0"
            >
              <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                <Dialog.Title className="mt-2 text-primary/70 text-sm">
                  Please accept the Terms & Conditions to proceed further.
                </Dialog.Title>
                <button
                  className="mt-4 px-4 py-2 rounded-full bg-secondary text-white text-xs font-medium"
                  type="button"
                  onClick={() => setModalOpen(false)}
                >
                  Close
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>
    </section>
  );
};

export default BasicDetails;
