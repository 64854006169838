import { Fragment, useMemo, useState } from "react";
import {
  createBrowserRouter,
  Link,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { Dialog, Transition, Menu } from "@headlessui/react";
import AuthProvider, { useAuth } from "./utils/auth";

import VendorLogo from "./assets/images/ReportVendorLogo.png";
import { ReactComponent as Rotate } from "./assets/icons/Rotate.svg";
import { ReactComponent as Chevron } from "./assets/icons/Chevron.svg";
import { ReactComponent as Plus } from "./assets/icons/Plus.svg";
import { ReactComponent as Menubar } from "./assets/icons/Menu.svg";
import { ReactComponent as Close } from "./assets/icons/Close.svg";
import { ReactComponent as DashboardIcon } from "./assets/icons/Dashboard.svg";
import { ReactComponent as MyAccountIcon } from "./assets/icons/MyAccount.svg";
import { ReactComponent as TutorialIcon } from "./assets/icons/Tutorial.svg";
import { ReactComponent as SupportIcon } from "./assets/icons/Support.svg";
import { ReactComponent as LogoutIcon } from "./assets/icons/Logout.svg";

import Login from "./screens/Login";
import TermsOfUse from "./screens/TermsOfUse";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import BasicDetails from "./screens/BasicDetails";
import MyAccount from "./screens/MyAccount";
import Tutorial from "./screens/Tutorial";
import Support from "./screens/Support";
import Dashboard from "./screens/Dashboard";
import SelectOption from "./screens/SelectOption";
import ScanInstructions from "./screens/ScanInstructions";
import SelectPosture from "./screens/SelectPosture";
import FaceScan from "./screens/FaceScan";
import FingerScan from "./screens/FingerScan";
import ScanResult from "./screens/ScanResult";
import Survey from "./screens/Survey";
import HeartHealth from "./screens/history/HeartHealth";
import BodyVitals from "./screens/history/BodyVitals";
import PhysioFitness from "./screens/history/PhysioFitness";
import StressIndex from "./screens/history/StressIndex";
import DetailHistory from "./screens/history/DetailHistory";

const Header = () => {
  const { pathname } = useLocation();
  const {
    logout,
    profileList,
    userData: { name },
  } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const greetingsText = useMemo(() => {
    const hourNow = new Date().getHours();
    return hourNow < 12
      ? "Good Morning"
      : hourNow < 17
      ? "Good Afternoon"
      : "Good Evening";
  }, []);

  return (
    <main className="min-h-screen max-w-sm mx-auto relative bg-white">
      <Transition show={isOpen} as={Fragment}>
        <Dialog as={Fragment} onClose={() => setIsOpen(false)}>
          <Transition.Child
            className="fixed top-0 bottom-0 left-0 right-0 bg-black/70"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200 transition-transform transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="duration-200 transition-transform transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel
                as="aside"
                className="min-h-full w-64 bg-white flex flex-col items-stretch"
              >
                <div className="flex items-start justify-between p-6">
                  <div className="w-40 text-black">
                    <Dialog.Title as="h3" className="text-sm">
                      {greetingsText}
                    </Dialog.Title>
                    <Dialog.Title
                      as="h2"
                      className="text-lg font-medium truncate"
                    >
                      {name}
                    </Dialog.Title>
                  </div>
                  <button
                    className="flex-shrink-0 bg-primary p-1.5 rounded-full"
                    type="button"
                    onClick={() => setIsOpen(false)}
                  >
                    <Close className="h-5 w-5 text-white" />
                  </button>
                </div>
                <nav className="mt-4">
                  <ul className="space-y-4">
                    {[
                      {
                        to: "/",
                        label: "Dashboard",
                        icon: (
                          <DashboardIcon className="h-5 w-5 text-primary" />
                        ),
                      },
                      {
                        to: "/my-account",
                        label: "My Account",
                        icon: (
                          <MyAccountIcon className="h-5 w-5 text-primary" />
                        ),
                      },
                      {
                        to: "/tutorial",
                        label: "Tutorial",
                        icon: <TutorialIcon className="h-5 w-5 text-primary" />,
                      },
                      {
                        to: "/support",
                        label: "Support",
                        icon: <SupportIcon className="h-5 w-5 text-primary" />,
                      },
                    ].map((linkObj) => (
                      <li key={linkObj.to} className="pr-6">
                        <Link
                          to={linkObj.to}
                          className={`flex items-center space-x-4 py-2 px-6 rounded-r-full ${
                            pathname === linkObj.to
                              ? "bg-secondary/10"
                              : "bg-transparent"
                          } hover:bg-secondary/5`}
                          onClick={() => setIsOpen(false)}
                        >
                          {linkObj.icon}
                          <span className="text-primary text-xs">
                            {linkObj.label}
                          </span>
                        </Link>
                      </li>
                    ))}
                    <li className="pr-6">
                      <button
                        type="button"
                        className="w-full flex items-center space-x-4 py-2 px-6 rounded-r-full hover:bg-error/5 active:bg-error/10"
                        onClick={() => {
                          setIsOpen(false);
                          logout();
                        }}
                      >
                        <LogoutIcon className="h-5 w-5 text-error" />
                        <span className="text-error text-xs">Logout</span>
                      </button>
                    </li>
                  </ul>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>
      <header className="relative flex items-center justify-between space-x-3 p-6">
        <img className="shrink-0 h-12" src={VendorLogo} alt="Vendor Logo" />
        <div className="flex items-center justify-between space-x-6">
          <Menu>
            <Menu.Button className="flex items-center justify-between bg-gradient-to-b from-secondary to-secondary/50 border border-primary text-white rounded-2xl px-4 py-1.5 space-x-2">
              <p className="text-xs">{name}</p>
              <Chevron className="shrink-0 h-3.5 w-3.5" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-100"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute top-16 right-16 mt-1 origin-top outline-none shadow-lg rounded-lg border border-lightgray/20 bg-[#fafafa] z-50">
                <h4 className="px-4 mt-4 mb-1.5 text-primary text-sm font-medium">
                  Select Profile
                </h4>
                <div className="min-w-[50vw] max-w-[70vw] divide-y divide-lightgray/20">
                  {profileList.map((profile) => (
                    <Menu.Item
                      as="button"
                      type="button"
                      onClick={() => {
                        localStorage.setItem(
                          "selected-profile-id",
                          profile.member_profile_id
                        );
                        window.location.reload(true);
                      }}
                      key={profile.member_profile_id}
                      className={`w-full px-4 py-2 flex items-center space-x-1 ${
                        profile.member_profile_id ===
                        (localStorage.getItem("selected-profile-id") ??
                          profileList[0]?.member_profile_id)
                          ? "bg-darkgray text-white"
                          : "text-primary"
                      } text-xs text-left capitalize`}
                    >
                      <p className="truncate">{profile.additional_info.name}</p>
                      <p className="opacity-70">
                        ({profile.relation?.toLowerCase?.()})
                      </p>
                    </Menu.Item>
                  ))}
                </div>
                <Link
                  to="basic-details"
                  className="mt-1.5 px-4 py-2 w-full flex items-center justify-center space-x-1.5 bg-lightgray/25 text-primary"
                >
                  <Plus className="shrink-0 h-3 w-3" />
                  <span className="text-xs">Add Profile</span>
                </Link>
              </Menu.Items>
            </Transition>
          </Menu>

          <button
            className="shrink-0 h-6 w-6"
            type="button"
            onClick={() => setIsOpen(true)}
          >
            <Menubar className="h-full w-full text-black" />
          </button>
        </div>
      </header>
      <Outlet />
    </main>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <Header />
      </AuthProvider>
    ),
    children: [
      { index: true, element: <Dashboard /> },
      { path: "my-account", element: <MyAccount /> },
      { path: "tutorial", element: <Tutorial /> },
      { path: "support", element: <Support /> },
      { path: "select-option", element: <SelectOption /> },
      { path: "scan-instructions", element: <ScanInstructions /> },
      { path: "select-posture", element: <SelectPosture /> },
      { path: "scan-result", element: <ScanResult /> },
      { path: "survey", element: <Survey /> },
      {
        path: "history",
        children: [
          { index: true, element: <DetailHistory /> },
          { path: "heart-health", element: <HeartHealth /> },
          { path: "body-vitals", element: <BodyVitals /> },
          { path: "physio-fitness", element: <PhysioFitness /> },
          { path: "stress-index", element: <StressIndex /> },
        ],
      },
    ],
  },
  {
    path: "terms-of-use",
    element: <TermsOfUse />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "face-scan",
    element: (
      <AuthProvider>
        <FaceScan />
      </AuthProvider>
    ),
  },
  {
    path: "finger-scan",
    element: (
      <AuthProvider>
        <FingerScan />
      </AuthProvider>
    ),
  },
  {
    path: "basic-details",
    element: (
      <AuthProvider>
        <BasicDetails />
      </AuthProvider>
    ),
  },
  {
    path: "/login",
    element: (
      <AuthProvider>
        <Login />
      </AuthProvider>
    ),
  },
]);

const App = () => (
  <>
    <RouterProvider router={router} />
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 h-screen w-screen hidden lg:flex xs:landscape:flex flex-col items-center justify-center bg-white">
      <Rotate className="flex-shrink-0 h-32 w-36" />
      <p className="mt-6 w-72 text-darkgray text-lg text-center">
        Please rotate your phone to access the application.
      </p>
    </div>
  </>
);

export default App;
