import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { faceScan } from "careplix-web-algo";
import { useAuth } from "../utils/auth";
import { sdkURL, api_key } from "../utils/api-url-list";

import LoadingScreen from "../components/LoadingScreen";

import Failure from "../assets/images/failure.gif";
import Analyzing from "../assets/images/analyzing.gif";

const scanMessages = [
  "We burn calories while we are asleep because brain activity requires energy",
  "Exercise promotes cardiovascular health, ensuring more blood and oxygen to circulate the body, helping to elevate energy",
  "Always sleep on your back straight since it allows your neck and spine in a neutral position",
  "Alcohol consumption disrupts chemical impulses between brain cells causing impulsive behaviour, slurred speech, impaired memory",
  "Meditation increases nitric oxide generation in the brain helping to lower your blood pressure",
  "Meditation reduces the production of cytokines in several recent studies, thus helping in stress reduction",
  "Laughing is good for your heart. It reduces stress and gives a boost to your immune system",
  "Lack of sleep can lead to heart disease, heart attack, or stroke",
  "Exercise boosts brain performance",
  "Heart pumps about 2,000 gallons of blood every day",
  "Your heart will beat about 115,000 times each day",
].sort(() => Math.random() - 0.5);

const getMessage = (type = "", timeElapsed = 0, isLightMode = false) => {
  if (type !== "scan") {
    if (timeElapsed <= 5000)
      return "Keep your head upright and look straight at the camera";
    else if (timeElapsed <= 10000)
      return "During the measurement, please do not speak or move";
    else if (timeElapsed <= 17000)
      return "Keep your device steady throughout the vital measurement";
    else if (timeElapsed <= 18000) return "We are good to start in 3";
    else if (timeElapsed <= 19000) return "We are good to start in 2";
    else if (timeElapsed <= 20000) return "We are good to start in 1";
  } else {
    if (timeElapsed <= 30000)
      return isLightMode
        ? "Scan in Progress...\nSwitching to the light mode for better accuracy"
        : "Scan in Progress...";
    else if (timeElapsed <= 40000) return scanMessages[0];
    else if (timeElapsed <= 50000) return scanMessages[1];
    else if (timeElapsed <= 60000) return scanMessages[2];
    else if (timeElapsed <= 70000) return scanMessages[3];
    else if (timeElapsed <= 80000) return scanMessages[4];
    else if (timeElapsed <= 90000) return scanMessages[5];
    else if (timeElapsed <= 100000) return scanMessages[6];
    else if (timeElapsed <= 110000) return scanMessages[7];
    else if (timeElapsed <= 120000) return scanMessages[8];
    else if (timeElapsed <= 130000) return scanMessages[9];
    else if (timeElapsed <= 140000) return scanMessages[10];
  }
};

const FaceScan = () => {
  const [analyzing, setAnalyzing] = useState(false);
  const [error, setError] = useState("");
  const [scanFrameData, setScanFrameData] = useState({
    type: "",
    timeElapsed: 0,
    isLightMode: false,
    fps: 0,
  });

  const { userData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scanToken?.length > 0) {
      faceScan.onFrame((fd) => setScanFrameData(fd));
      faceScan.onScanFinish(
        async ({ raw_intensity, ppg_time, average_fps }) => {
          try {
            setAnalyzing(true);
            const resp = await fetch(sdkURL("/vitals/add-scan"), {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                api_key,
                scan_token: location.state.scanToken,
                employee_id: userData.employee_id,
                profile_id: userData.profile_id,
                posture: location.state?.posture ?? "resting",
                dob: userData.dob,
                gender: userData.gender,
                metadata: {
                  physiological_scores: {
                    height: userData.height,
                    weight: userData.weight,
                  },
                  ppg_time: ppg_time,
                  raw_intensity: raw_intensity,
                  device: navigator.platform.match(/iPhone|iPod|iPad/)
                    ? "RPPG_CAREPLIX_FACE_IOS"
                    : "RPPG_CAREPLIX_FACE_ANDROID",
                  fps: average_fps,
                },
              }),
            });
            const resp_json = await resp.json();
            if (resp_json.statusCode?.toString().startsWith("2"))
              navigate("/survey", {
                state: { result: resp_json },
                replace: true,
              });
            else throw new Error(resp_json.message);
          } catch (err) {
            console.error(err);
            setError(err.message);
          }
        }
      );
      faceScan.onError((err) => {
        console.error(err);
        setError(err.message);
      });
      faceScan
        .startScan(60000, 60000, "/model", 3000, {
          drawType: "face-circle",
          color: "#fff",
        })
        .then(() => console.log("Scan Started"))
        .catch(console.error);
    } else setError("Invalid Scan Token");
    return () => {
      faceScan.stopScan(true);
    };
  }, [location, navigate, userData]);

  return (
    <div className="relative h-screen bg-white">
      {error.length > 0 ? (
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-white px-8 py-12 flex flex-col items-center justify-center text-center">
          <img src={Failure} alt="failure icon" className="w-48 mx-auto" />
          <p className="mt-3 text-primary text-center">
            Scan Failed!
            <br />
            Please try again.
          </p>
          <button
            className="mt-8 px-4 py-2 rounded-lg bg-red-500 text-white text-sm font-medium"
            type="button"
            onClick={() => {
              navigate(-1);
            }}
          >
            Go Back
          </button>
        </div>
      ) : analyzing ? (
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-white px-8 py-12 flex flex-col items-center justify-center text-center">
          <img src={Analyzing} alt="analyzing icon" className="w-48 mx-auto" />
          <p className="mt-3 text-primary text-xl">Analyzing Data</p>
          <p className="mt-2 text-primary font-light">
            Hold tight, the measurement
            <br />
            results are on the way
          </p>
        </div>
      ) : (
        <>
          <div className="relative h-full w-full">
            <video
              className="fixed bottom-12 left-8 w-px h-px bg-white/80"
              id="videoInput"
              autoPlay
              muted
              playsInline
            />
            <canvas id="canvasOutput" className="h-full w-full -scale-x-100" />
            {!faceScan.isFaceInView() && (
              <div className="fixed left-0 right-0 top-[30vh]">
                <svg className="w-48 mx-auto" viewBox="0 0 150 150">
                  <path
                    fill="#47393D"
                    d="M127.64,113.99l-0.82-0.34c11.02-26.27-2.95-37.62-12.26-42.04c-11.13-5.29-14.54-13.34-14.68-13.69 l0.82-0.34c0.03,0.08,3.43,8.09,14.24,13.22C131.27,78.56,135.89,94.3,127.64,113.99z"
                  />
                  <path
                    fill="#47393D"
                    d="M125.88,107.74L79.04,92.08l-41.08,21.59c-7.98-31.19,13.25-32.61,14.16-55.3 c0.47-11.75,3.13-24.18,10.25-28.48c4.06-2.45,9.21-1.36,9.21-1.36c5.88-5.2,15.58-2.32,19.44,1.36 c4.47,4.26,7.64,11.35,9.01,21.69C103.02,74.1,131.6,72.12,125.88,107.74z"
                  />
                  <path
                    fill="#D5E6F7"
                    d="M83.84,68.46H66.6l-2.57,21.1c1.93,2.46,6.5,7.18,12.94,6.53c4.37-0.44,7.46-3.51,9.44-6.44 C86.65,89.65,83.84,68.46,83.84,68.46z"
                  />
                  <path
                    fill="#47393D"
                    d="M85.42,80.7c-12.71,5.27-18.27-2.4-18.27-2.4l17.26-5.5C84.71,75.09,85.08,77.96,85.42,80.7z"
                  />
                  <path
                    fill="#F5FAFF"
                    d="M91.74,43.59c3.12,10.53,0.27,37.84-16.25,37.89c-17.71,0.05-18.56-29.63-15.61-39.17 C63.68,30.03,88.35,32.18,91.74,43.59z"
                  />
                  <path
                    fill="#47393D"
                    d="M92.71,53.18c-15.8,1.8-21.81-10.74-21.81-10.74c-10.62-4.63-12.88,9.61-12.88,9.61s-2.39-6.81,0.86-13.49 c5.58-11.48,28.64-7.56,32.8-1.08c6.51,10.15,3.3,17.51,3.3,17.51L92.71,53.18z"
                  />
                  <path
                    fill="#47393D"
                    d="M85.25,54.79c-3.1,0-5.81-0.74-8.09-2.21c-5.67-3.65-6.65-10.43-6.69-10.72l0.88-0.12 c0.01,0.07,0.97,6.67,6.3,10.1c3.77,2.42,8.8,2.71,14.95,0.86l0.26,0.85C90.1,54.37,87.56,54.79,85.25,54.79z"
                  />
                  <path
                    fill="#47393D"
                    d="M52.55,53.3c-0.09-0.64-2.15-15.82,5.22-22.48c3.29-2.98,7.96-3.75,13.85-2.31l-0.21,0.86 c-5.6-1.37-9.99-0.67-13.05,2.1c-7.02,6.35-4.96,21.55-4.94,21.7L52.55,53.3z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M9.74,143.2c0,0,4.15-47.74,54.29-53.64h22.86c0,0,54.11,6.15,54.11,53.64H9.74z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M64.03,89.56l0.92-7.28c7.31,3.86,14.21,3.93,20.64,0l1.29,7.28c0,0-2.65,7.24-11.84,7.39 C66.91,97.08,64.03,89.56,64.03,89.56z"
                  />
                  <path
                    fill="#47393D"
                    d="M78.01,139.53c-13.79,0-26.01-1.87-37.67-5.64l0.27-0.84c20.3,6.57,42.36,7.32,71.51,2.44l0.15,0.87 C99.68,138.47,88.4,139.53,78.01,139.53z"
                  />
                  <path
                    fill="#47393D"
                    d="M77.22,130.73c-6.88,0-13.35-0.47-19.61-1.41c-5.86-0.88-11.61-2.2-17.09-3.92l0.27-0.84 c5.43,1.71,11.14,3.01,16.96,3.89c8.47,1.28,17.32,1.68,27.05,1.22c8.07-0.38,16.74-1.35,26.53-2.99l0.15,0.87 c-9.82,1.64-18.53,2.62-26.63,3C82.23,130.67,79.7,130.73,77.22,130.73z"
                  />
                  <path
                    fill="#47393D"
                    d="M76.38,121.91c-6.49,0-12.61-0.42-18.57-1.27c-5.63-0.79-11.19-2-16.52-3.58l0.25-0.85 c5.29,1.57,10.81,2.77,16.39,3.55c8.15,1.16,16.63,1.51,25.93,1.08c8.16-0.37,16.87-1.35,26.63-2.99l0.15,0.87 c-9.79,1.64-18.54,2.62-26.74,3C81.34,121.85,78.83,121.91,76.38,121.91z"
                  />
                  <path
                    fill="#47393D"
                    d="M75.6,113.1c-6.13,0-11.93-0.37-17.58-1.12c-5.46-0.73-10.83-1.83-15.96-3.27l0.24-0.85 c5.09,1.43,10.41,2.52,15.83,3.24c7.82,1.04,15.93,1.35,24.81,0.95c8.2-0.38,16.94-1.36,26.74-3l0.15,0.87 c-9.83,1.65-18.61,2.63-26.84,3.01C80.47,113.04,78.01,113.1,75.6,113.1z"
                  />
                  <path
                    fill="#47393D"
                    d="M74.78,104.28c-5.67,0-11.08-0.33-16.38-0.98l-0.16-0.02c-5.14-0.62-10.25-1.6-15.22-2.91l-0.09-0.02 l0.11-0.88l0.2,0.05c4.92,1.3,10,2.27,15.1,2.89l0.17,0.02c7.48,0.93,15.17,1.19,23.52,0.81l0.13-0.01 c8.03-0.36,16.77-1.33,26.71-2.99l0.15,0.87c-9.98,1.66-18.75,2.64-26.81,3l-0.13,0.01C79.58,104.23,77.16,104.28,74.78,104.28z"
                  />
                  <path
                    fill="#47393D"
                    d="M75.76,95.53c-12.73,0-24.03-1.88-32.73-4.01l-0.09-0.02l0.12-0.88l0.19,0.04c15.52,3.8,39.37,6.81,64.83-0.39l0.24,0.85C96.94,94.34,85.89,95.53,75.76,95.53z"
                  />
                  <path
                    fill="#2F99F1"
                    d="M64.03,89.56l-4.16,53.5L9.74,143.2C9.74,143.2,12.78,92.41,64.03,89.56z"
                  />
                  <path
                    fill="#2F99F1"
                    d="M86.88,89.56l4.16,53.5l50.13,0.14C141.17,143.2,143,94.54,86.88,89.56z"
                  />
                  <path
                    fill="#47393D"
                    d="M36.07,143.14l-0.84-0.08c0.03-0.36,0.82-8.88,3.12-14.32l0.78,0.33C36.88,134.39,36.08,143.05,36.07,143.14	z"
                  />
                  <path
                    fill="#47393D"
                    d="M117,143.15c-0.01-0.09-0.76-8.75-2.98-14.09l0.78-0.32c2.27,5.46,3.01,13.98,3.04,14.34L117,143.15z"
                  />
                  <path
                    fill="#192853"
                    d="M29.19,37.73h-1.78V17.98C27.41,11.93,32.59,7,38.97,7h20.8v1.69h-20.8c-5.39,0-9.78,4.17-9.78,9.29V37.73z"
                  />
                  <path
                    fill="#192853"
                    d="M59.77,98.1h-20.8c-6.38,0-11.56-4.93-11.56-10.98V67.36h1.78v19.76c0,5.12,4.39,9.29,9.78,9.29h20.8 V98.1z"
                  />
                  <path
                    fill="#192853"
                    d="M123.32,37.73h-1.78V17.98c0-5.12-4.39-9.29-9.78-9.29H90.97V7h20.79c6.38,0,11.56,4.93,11.56,10.98 V37.73z"
                  />
                  <path
                    fill="#192853"
                    d="M111.76,98.1H90.97v-1.69h20.79c5.39,0,9.78-4.17,9.78-9.29V67.36h1.78v19.76 C123.32,93.18,118.14,98.1,111.76,98.1z"
                  />
                  <path
                    fill="#47393D"
                    d="M69.21,28.76c0,0-6.32-5.77-0.08-8.42c0,0,1.98-5.68,7.22-4.34c5.89,1.5,4.41,9.68,1.58,12.64L69.21,28.76z"
                  />
                  <path
                    fill="#47393D"
                    d="M69.06,29.06c-0.05-0.03-5.22-2.69-3.83-6.76c1.08-3.18,4.31-2.27,4.34-2.26l-0.19,0.64c-0.11-0.03-2.65-0.73-3.52,1.84c-1.2,3.53,3.45,5.93,3.5,5.96L69.06,29.06z"
                  />
                  <path
                    fill="#47393D"
                    d="M79.37,28.35l-0.51-0.42c0.03-0.03,2.49-3.07,2.13-6.23c-0.17-1.52-0.97-2.83-2.38-3.91l0.4-0.53c1.55,1.19,2.44,2.66,2.63,4.37C82.03,25.07,79.47,28.22,79.37,28.35z"
                  />
                </svg>
                <p className="mt-2 text-xl text-center text-white drop-shadow">
                  Cannot Detect Face
                </p>
              </div>
            )}
            {faceScan.canStop() && (
              <button
                type="button"
                onClick={() => faceScan.stopScan()}
                className="fixed top-8 left-4 rounded-full px-3 py-1.5 text-xs text-white bg-[#ED1749]"
              >
                Finish Scan
              </button>
            )}
            <div
              className="fixed top-8 right-4 rounded-full px-3 py-1.5 text-xs text-white bg-[#f31e2e]"
              style={{
                backgroundColor:
                  scanFrameData.fps > 15
                    ? scanFrameData.fps > 20
                      ? "#a0dd0c"
                      : "#eea508"
                    : "#f31e2e",
              }}
            >
              Signal Strength:&nbsp;
              <span className="font-semibold">
                {scanFrameData.fps > 15
                  ? scanFrameData.fps > 20
                    ? "Good"
                    : "Moderate"
                  : "Poor"}
              </span>
            </div>
            <div className="fixed bottom-10 left-6 right-6 p-6 rounded-xl bg-white/80 flex flex-col">
              <h3 className="text-primary font-semibold">
                {scanFrameData.type === "scan"
                  ? `${Math.round(
                      ((scanFrameData.timeElapsed - 20000) / 60000) * 100
                    )}% Completed...`
                  : `Calibration in progress...`}
              </h3>
              <h4 className="mt-2 text-primary text-xs">
                {getMessage(
                  scanFrameData.type,
                  scanFrameData.timeElapsed,
                  scanFrameData.isLightMode
                )}
              </h4>
              <button
                type="button"
                onClick={() => {
                  faceScan.stopScan(true);
                  navigate(-1);
                }}
                className="self-end mt-4 text-[#ED1749] text-sm font-medium"
              >
                &lt;&nbsp;Cancel Scan
              </button>
            </div>
          </div>
          {faceScan.isInitializing() && (
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-white px-8 py-12 flex flex-col items-center justify-center text-center">
              <LoadingScreen />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FaceScan;
