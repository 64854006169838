import { Fragment, useMemo, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Dialog, Disclosure, Tab, Transition } from "@headlessui/react";
import ProgressCircle from "../components/ProgressCircle";
import {
  Font,
  Document,
  Page,
  StyleSheet,
  View,
  Text,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import { useAuth } from "../utils/auth";
import { constants } from "../utils/ranges";

import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";
import { ReactComponent as Info } from "../assets/icons/Info.svg";
import { ReactComponent as Caret } from "../assets/icons/Caret.svg";
import { ReactComponent as Download } from "../assets/icons/Download.svg";

import CompanyLogo from "../assets/images/Logo.png";
import VendorLogo from "../assets/images/ReportVendorLogo.png";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf",
      fontWeight: "light",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
      fontWeight: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf",
      fontWeight: "medium",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf",
      fontWeight: "semibold",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf",
      fontWeight: "bold",
    },
  ],
});

const PDFReport = ({
  name = "",
  age = 0,
  gender = "",
  height = 0,
  weight = 0,
  result = {
    timestamp: new Date(),
    bp_sys: 0,
    bp_dia: 0,
    resp_rate: 0,
    oxy_sat_prcnt: 0,
    heart_rate: 0,
    bmi: 0,
    sdnn: 0,
    rmssd: 0,
    pNN50_per: 0,
    cardiac_out: 0.0,
    map: 0,
    heart_utilized: 0,
    HRMax: 0,
    HRR: 0,
    THRR: 0,
    vo2max: 0.0,
  },
}) => {
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#fff",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "space-between",
    },
    header: {
      backgroundColor: "#f7f7f7",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingHorizontal: 48,
    },
    headerIcon: { flex: 0.3 },
    contentContainer: { flex: 1, paddingVertical: 32, paddingHorizontal: 64 },
    rowContainer: { flexDirection: "row", justifyContent: "space-between" },
    largeText: { fontFamily: "Poppins", fontSize: 15, fontWeight: "bold" },
    normalText: { fontFamily: "Poppins", fontSize: 12, fontWeight: "semibold" },
    smallText: { fontFamily: "Poppins", fontSize: 10 },
    footer: { height: 48, backgroundColor: "#000" },
  });

  return (
    <Document
      title="CarePlix Report"
      subject="Report generated from CarePlix Vitals Scan"
      author="CarePlix"
    >
      <Page style={styles.page}>
        <View fixed style={styles.header}>
          <Image style={styles.headerIcon} source={CompanyLogo} />
          <Image style={styles.headerIcon} source={VendorLogo} />
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.rowContainer}>
            <View>
              <Text style={[styles.largeText, { textTransform: "capitalize" }]}>
                {name}
              </Text>
              {[
                { label: "Gender", value: gender },
                { label: "Age", value: `${age} years` },
                { label: "Height", value: `${height} cms` },
                { label: "Weight", value: `${weight} kgs` },
              ].map(({ label, value }) => (
                <Text
                  key={label.toLowerCase().replaceAll(" ", "-")}
                  style={[styles.normalText, { fontWeight: "normal" }]}
                >
                  <Text style={{ fontWeight: "semibold" }}>{label}: </Text>
                  <Text>{value}</Text>
                </Text>
              ))}
            </View>
            <View>
              {[
                {
                  label: "Assessment Date",
                  value: result.timestamp.toLocaleDateString(undefined, {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  }),
                },
                {
                  label: "Assessment Time",
                  value: result.timestamp.toLocaleTimeString(undefined, {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  }),
                },
              ].map(({ label, value }) => (
                <Text
                  key={label.toLowerCase().replaceAll(" ", "-")}
                  style={[styles.normalText, { fontWeight: "normal" }]}
                >
                  <Text style={{ fontWeight: "semibold" }}>{label}: </Text>
                  <Text>{value}</Text>
                </Text>
              ))}
            </View>
          </View>
          <Text
            style={[
              styles.largeText,
              {
                alignSelf: "center",
                marginVertical: 36,
                paddingVertical: 8,
                paddingHorizontal: 32,
                borderRadius: 10,
                backgroundColor: "#000",
                color: "#fff",
                fontWeight: "medium",
              },
            ]}
          >
            Digital Health Assessment Report
          </Text>
          <View style={styles.rowContainer}>
            <View style={{ flex: 4 }}>
              <Text style={styles.normalText}>Key Body Vitals</Text>
              {["Blood Pressure", "Respiration Rate", "Oxygen Saturation"].map(
                (e, idx) => (
                  <Text
                    key={`entry-${idx}`}
                    style={[styles.smallText, { marginTop: 10 }]}
                  >
                    {e}
                  </Text>
                )
              )}
            </View>
            <View style={{ flex: 3 }}>
              <Text style={styles.normalText}>Value</Text>
              {[
                `${result.bp_sys}/${result.bp_dia} mmHg`,
                `${result.resp_rate}/min`,
                `${result.oxy_sat_prcnt}%`,
              ].map((e, idx) => (
                <Text
                  key={`entry-${idx}`}
                  style={[styles.smallText, { marginTop: 10 }]}
                >
                  {e}
                </Text>
              ))}
            </View>
            <View style={{ flex: 3 }}>
              <Text style={styles.normalText}>Range</Text>
              {["90-120/60-80 mmHg", "12-20/min", "95-100%"].map((e, idx) => (
                <Text
                  key={`entry-${idx}`}
                  style={[styles.smallText, { marginTop: 10 }]}
                >
                  {e}
                </Text>
              ))}
            </View>
          </View>
          <View style={[styles.rowContainer, { marginTop: 36 }]}>
            <View style={{ flex: 4 }}>
              <Text style={styles.normalText}>Heart Health</Text>
              {["Heart Rate"].map((e, idx) => (
                <Text
                  key={`entry-${idx}`}
                  style={[styles.smallText, { marginTop: 10 }]}
                >
                  {e}
                </Text>
              ))}
            </View>
            <View style={{ flex: 3 }}>
              <Text style={styles.normalText}>Value</Text>
              {[`${result.heart_rate} bpm`].map((e, idx) => (
                <Text
                  key={`entry-${idx}`}
                  style={[styles.smallText, { marginTop: 10 }]}
                >
                  {e}
                </Text>
              ))}
            </View>
            <View style={{ flex: 3 }}>
              <Text style={styles.normalText}>Range</Text>
              {["60-100 bpm"].map((e, idx) => (
                <Text
                  key={`entry-${idx}`}
                  style={[styles.smallText, { marginTop: 10 }]}
                >
                  {e}
                </Text>
              ))}
            </View>
          </View>
          <View style={[styles.rowContainer, { marginTop: 36 }]}>
            <View style={{ flex: 4 }}>
              <Text style={styles.normalText}>Physiological</Text>
              <Text
                style={{
                  marginTop: 4,
                  width: "75%",
                  fontFamily: "Poppins",
                  fontSize: 9,
                  fontWeight: "light",
                }}
              >
                *Calculated based on height and weight given by you.
              </Text>
              {["BMI"].map((e, idx) => (
                <Text
                  key={`entry-${idx}`}
                  style={[styles.smallText, { marginTop: 10 }]}
                >
                  {e}
                </Text>
              ))}
            </View>
            <View style={{ flex: 3, justifyContent: "space-between" }}>
              <Text style={styles.normalText}>Value</Text>
              {[`${result.bmi} kg/m²`].map((e, idx) => (
                <Text
                  key={`entry-${idx}`}
                  style={[styles.smallText, { marginTop: 10 }]}
                >
                  {e}
                </Text>
              ))}
            </View>
            <View style={{ flex: 3, justifyContent: "space-between" }}>
              <Text style={styles.normalText}>Range</Text>
              {["18.5-24.9 kg/m²"].map((e, idx) => (
                <Text
                  key={`entry-${idx}`}
                  style={[styles.smallText, { marginTop: 10 }]}
                >
                  {e}
                </Text>
              ))}
            </View>
          </View>
          <View
            style={{
              marginTop: 36,
              padding: 24,
              borderRadius: 10,
              backgroundColor: "#f1f1f1",
            }}
          >
            <Text style={[styles.normalText, { fontWeight: "bold" }]}>
              DISCLAIMER:
            </Text>
            <Text
              style={[styles.smallText, { marginTop: 8, textAlign: "justify" }]}
            >
              For Investigational Use Only. Careplix is not a substitute for the
              clinical judgment of a health care professional. Careplix is
              intended to improve your awareness of general wellness. Careplix
              does not diagnose, treat, mitigate or prevent any disease,
              symptom, disorder or abnormal physical state. Consult with a
              health care professional or emergency services if you believe you
              may have a medical issue.
            </Text>
          </View>
        </View>
        <View style={styles.contentContainer} break>
          <View style={styles.rowContainer}>
            <View style={{ flex: 4 }}>
              <Text style={styles.normalText}>Heart Health</Text>
              {[
                "SDNN",
                "RMSSD",
                "PNN50",
                "Cardiac Output",
                "Mean Arterial Pressure",
                "Heart Utilization\n ",
                "Heart Rate Max",
                "Heart Rate Reserve",
                "Target HR Range",
                "VO2Max",
              ].map((e, idx) => (
                <Text
                  key={`entry-${idx}`}
                  style={[styles.smallText, { marginTop: 10 }]}
                >
                  {e}
                </Text>
              ))}
            </View>
            <View style={{ flex: 3 }}>
              <Text style={styles.normalText}>Value</Text>
              {[
                `${result.sdnn} msec`,
                `${result.rmssd} msec`,
                `${result.pNN50_per}%`,
                `${result.cardiac_out} L/min`,
                `${result.map} mmHg`,
                `${result.heart_utilized}%\n `,
                `${result.HRMax} bpm`,
                `${result.HRR} bpm`,
                `${result.THRR} bpm`,
                `${result.vo2max} mL/kg/min`,
              ].map((e, idx) => (
                <Text
                  key={`entry-${idx}`}
                  style={[styles.smallText, { marginTop: 10 }]}
                >
                  {e}
                </Text>
              ))}
            </View>
            <View style={{ flex: 3 }}>
              <Text style={styles.normalText}>Range</Text>
              {[
                "60-100 msec",
                "20-89 msec",
                ">3%",
                "4-8 L/min",
                "70-100 mmHg",
                "<50% (At rest)\n51-85% (Moderate intensity)",
                "--",
                "--",
                "--",
                "42.5 ml/kg/min (Male)\n33 ml/kg/min (Female)",
              ].map((e, idx) => (
                <Text
                  key={`entry-${idx}`}
                  style={[styles.smallText, { marginTop: 10 }]}
                >
                  {e}
                </Text>
              ))}
            </View>
          </View>
          <View
            style={{
              marginTop: 36,
              padding: 24,
              borderRadius: 10,
              backgroundColor: "#f1f1f1",
            }}
          >
            <Text style={[styles.normalText, { fontWeight: "bold" }]}>
              DISCLAIMER:
            </Text>
            <Text
              style={[styles.smallText, { marginTop: 8, textAlign: "justify" }]}
            >
              For Investigational Use Only. Careplix is not a substitute for the
              clinical judgment of a health care professional. Careplix is
              intended to improve your awareness of general wellness. Careplix
              does not diagnose, treat, mitigate or prevent any disease,
              symptom, disorder or abnormal physical state. Consult with a
              health care professional or emergency services if you believe you
              may have a medical issue.
            </Text>
          </View>
        </View>
        <View style={styles.contentContainer} break>
          {[
            {
              title: "Heart Rate",
              desc: "The heart rate is the number of times heart beats in a minute. A normal resting heart rate for adults ranges from 60 to 100 beats per minute.",
            },
            {
              title: "Respiration Rate",
              desc: "The respiration rate is the number of breaths taken in a minute. The rate is usually measured when a person is at rest and simply involves counting the number of breaths for one minute by counting how many times the chest rises.",
            },
            {
              title: "Oxygen Saturation",
              desc: "Oxygen saturation (SpO2) is the measurement of how much oxygen the blood is carrying as a percentage of the maximum it could carry.",
            },
            {
              title: "Blood Pressure",
              desc: "Blood pressure is a measure of the force that the heart uses to pump blood around the body. Blood pressure is measured in millimeters of mercury (mmHg) and is given as 2 figures:\nSystolic pressure - the pressure when your heart pushes blood out\nDiastolic pressure - the pressure when your heart rests between beats",
            },
            {
              title: "Stress Index",
              desc: "Stress is the reaction to everyday pressure due to emotional or physical tension. Stress Index < 1.5 is considered to be normal.",
            },
            {
              title: "Blood Volume",
              desc: "Blood volume refers to the amount of blood present in an individual's circulatory system. The average human adult has nearly 4.5-5.7  liters of circulating blood.",
            },
            {
              title: "BMI",
              desc: "BMI is the measure of body fat based on height and weight. The normal range of BMI is 18.5 - 24.9 kg/m².",
            },
          ].map(({ title, desc }) => (
            <View
              key={`info-${title.toLowerCase().replaceAll(" ", "-")}`}
              style={{ marginBottom: 20 }}
              wrap={false}
            >
              <Text style={[styles.largeText, { fontWeight: "semibold" }]}>
                {title}
              </Text>
              <Text
                style={[
                  styles.normalText,
                  { fontWeight: "light", textAlign: "justify", marginTop: 6 },
                ]}
              >
                {desc}
              </Text>
            </View>
          ))}
        </View>
        <View style={styles.contentContainer} break>
          {[
            {
              title: "Total Body Water & Body water Percentage",
              desc: "Total body water is the amount of water present in the human body. This includes fluids both inside and outside of cells. An adult body water percentage should fall between 40% and 60%.",
            },
            {
              title: "Calorie from Carb & Fat",
              desc: "Calorie from carbohydrate is that amount of energy obtained from consumption of Carbohydrate. Calorie from fat is the measurement of the energy obtained from consumption of fat.",
            },
            {
              title: "Heart Rate Max",
              desc: "The highest number of beats the heart can pump per minute is the maximum heart rate.",
            },
            {
              title: "Total Body Fat Percentage",
              desc: "Body fat percentage is basically the proportion of the mass of fat in our body relative to the total mass of our body.",
            },
            {
              title: "SDNN",
              desc: "The standard deviation of all of the NN intervals (the time intervals between each heartbeat). Higher numbers usually indicate that your body is coping better with stress.",
            },
            {
              title: "RMSSD",
              desc: "The root mean square of successive intervals between normal heartbeats (RMSSD). RMSSD is obtained by first calculating each successive time intervals between heartbeats in msec.",
            },
            {
              title: "PNN50",
              desc: "PNN50 is the proportion of adjacent N-N intervals differing by more than 50 msec. pNN50 lower than 3% is considered indicative of high risk.",
            },
          ].map(({ title, desc }) => (
            <View
              key={`info-${title.toLowerCase().replaceAll(" ", "-")}`}
              style={{ marginBottom: 20 }}
              wrap={false}
            >
              <Text style={[styles.largeText, { fontWeight: "semibold" }]}>
                {title}
              </Text>
              <Text
                style={[
                  styles.normalText,
                  { fontWeight: "light", textAlign: "justify", marginTop: 6 },
                ]}
              >
                {desc}
              </Text>
            </View>
          ))}
        </View>
        <View style={styles.contentContainer} break>
          {[
            {
              title: "Cardiac Output",
              desc: "The amount of blood heart pumps through the circulatory system in a minute. Cardiac output in humans is generally 4-8 L/min in an at-rest.",
            },
            {
              title: "Mean Arterial Pressure",
              desc: "MAP is the average arterial pressure throughout one heart cycle. MAP anything between 70 and 100 mmHg is considered to be normal.",
            },
            {
              title: "Heart Utilization",
              desc: "Heart utilization is the percentage of overall heart usage to its maximum peak heart rate. Generally < 50% utilization at rest and 51-85% utilization at moderate intensity is considered as normal.",
            },
            {
              title: "Heart Rate Reserve",
              desc: "Heart rate reserve is the difference between your maximum (peak) heart rate and your resting heart rate.",
            },
            {
              title: "VO2Max",
              desc: "VO2Max is the amount of oxygen the body uses during exercise. It is a measurement to understand Physical fitness.",
            },
            {
              title: "Target Heart Rate",
              desc: "To achieve a certain level of exertion, there is a minimum number of heartbeats required within a specific timeframe.",
            },
          ].map(({ title, desc }) => (
            <View
              key={`info-${title.toLowerCase().replaceAll(" ", "-")}`}
              style={{ marginBottom: 20 }}
              wrap={false}
            >
              <Text style={[styles.largeText, { fontWeight: "semibold" }]}>
                {title}
              </Text>
              <Text
                style={[
                  styles.normalText,
                  { fontWeight: "light", textAlign: "justify", marginTop: 6 },
                ]}
              >
                {desc}
              </Text>
            </View>
          ))}
        </View>
        <View fixed style={styles.footer} />
      </Page>
    </Document>
  );
};

const Range = ({
  ranges = [
    { point: 0, label: "Start", color: "#ccc" },
    { point: 100, label: "End", color: "#000" },
  ],
  selectedIndex,
  className = "",
}) => {
  selectedIndex -= ranges.length - 1 === selectedIndex;
  return (
    <div className={`relative grid grid-flow-col auto-cols-fr ${className}`}>
      {ranges.slice(0, -1).map((rangeItem, index) => (
        <div key={`range-${index}`} className="flex flex-col justify-end">
          {index === selectedIndex && (
            <Caret
              className="self-center flex-shrink-0 w-2 h-1"
              style={{ color: ranges[selectedIndex].color }}
            />
          )}
          <h6
            className="text-primary/60 text-center text-xxs font-medium uppercase truncate"
            style={
              index === selectedIndex
                ? { color: ranges[selectedIndex].color }
                : {}
            }
          >
            {rangeItem.label}
          </h6>
          <div
            className="my-1 h-1 bg-lightgray/10"
            style={
              index === selectedIndex
                ? { backgroundColor: ranges[selectedIndex].color }
                : {}
            }
          />
          <p className="text-primary/70 text-xxs">{rangeItem.point}</p>
        </div>
      ))}
      <p className="absolute bottom-0 right-0 text-primary/70 text-xxs">
        {ranges[ranges.length - 1].point}
      </p>
    </div>
  );
};

const ListItemContainer = ({
  label = "",
  defaultExpanded = false,
  title,
  desc,
  className = "",
  ...props
}) => {
  return (
    <Disclosure
      defaultOpen={defaultExpanded}
      as="div"
      className="px-6 py-4 rounded-2xl bg-white shadow-box"
    >
      <Disclosure.Button as="div">
        {({ open }) => [
          <div
            key="list-item-container-head"
            className="flex items-start justify-between space-x-1"
          >
            <h2 className="text-primary text-xs font-medium">{label}</h2>
            <Chevron
              className={`h-3 w-3 text-primary/60 transition-transform duration-300 ${
                open ? "rotate-180" : "rotate-0"
              }`}
            />
          </div>,
          <Fragment key="list-item-container-body">{props.children}</Fragment>,
        ]}
      </Disclosure.Button>
      <Disclosure.Panel>
        {typeof title === "string" && (
          <h5 className="mt-2.5 text-primary text-xs font-medium">{title}</h5>
        )}
        {typeof desc === "string" && (
          <p className="mt-1.5 text-primary/70 text-xxs">{desc}</p>
        )}
      </Disclosure.Panel>
    </Disclosure>
  );
};

const ScanResult = () => {
  const {
    userData: { name, dob, gender, height, weight },
  } = useAuth();
  const age = useMemo(() => {
    const ageDifMs = Date.now() - new Date(dob).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }, [dob]);
  const location = useLocation();
  const result = location.state?.result;
  const [modalState, setModalState] = useState({
    visible: false,
    title: "",
    desc: "",
  });
  const closeModal = () =>
    setModalState({ visible: false, title: "", desc: "" });

  return result?.scan_id?.length > 0 ? (
    <section className="px-6 py-2">
      <Tab.Group>
        <Tab.List className="flex flex-wrap items-center justify-center">
          <Tab
            className={({ selected }) =>
              `m-1 px-4 py-2 rounded-full border border-secondary ${
                selected ? "bg-secondary text-white" : "bg-white text-primary"
              } text-xxs font-medium`
            }
          >
            Key Body Vitals
          </Tab>
          <Tab
            className={({ selected }) =>
              `m-1 px-4 py-2 rounded-full border border-secondary ${
                selected ? "bg-secondary text-white" : "bg-white text-primary"
              } text-xxs font-medium`
            }
          >
            Heart Health
          </Tab>
          <Tab
            className={({ selected }) =>
              `m-1 px-4 py-2 rounded-full border border-secondary ${
                selected ? "bg-secondary text-white" : "bg-white text-primary"
              } text-xxs font-medium`
            }
          >
            Physiological
          </Tab>
          <Tab
            className={({ selected }) =>
              `m-1 px-4 py-2 rounded-full border border-secondary ${
                selected ? "bg-secondary text-white" : "bg-white text-primary"
              } text-xxs font-medium`
            }
          >
            Stress &amp; ANS
          </Tab>
          <Tab
            className={({ selected }) =>
              `m-1 px-4 py-2 rounded-full border border-secondary ${
                selected ? "bg-secondary text-white" : "bg-white text-primary"
              } text-xxs font-medium`
            }
          >
            Health Score
          </Tab>
        </Tab.List>
        <Tab.Panels className="my-6 space-y-6">
          <Tab.Panel className="space-y-6">
            {[
              { label: "Heart Rate", value: result?.vitals.heart_rate },
              { label: "Respiration Rate", value: result?.vitals.resp_rate },
              {
                label: "Oxygen Saturation",
                value: result?.vitals.oxy_sat_prcnt,
              },
            ].map((item, index) => {
              const ranges =
                item.label === "Heart Rate"
                  ? constants[item.label].ranges[result?.posture ?? "resting"]
                  : constants[item.label].ranges;
              const selectedRangeIndex = ranges.reduce(
                (p, c, ci) => (item.value >= c.point ? ci : p),
                0
              );
              return (
                <ListItemContainer
                  key={`section-item-${index}`}
                  label={item.label}
                  title={`Your ${item.label} is ${ranges[selectedRangeIndex].label}`}
                  desc={
                    constants[item.label].info +
                    "\n\n" +
                    ranges[selectedRangeIndex].desc
                  }
                >
                  <div className="py-3 flex items-center justify-between space-x-2">
                    <div className="flex-shrink-0 basis-1/4 text-center font-semibold">
                      <h4 className="text-secondary text-xl">{item.value}</h4>
                      <h5 className="text-primary/70 text-xxs">
                        {constants[item.label].unit}
                      </h5>
                    </div>
                    <Range
                      className="flex-grow"
                      ranges={ranges}
                      selectedIndex={selectedRangeIndex}
                    />
                  </div>
                </ListItemContainer>
              );
            })}
            {(() => {
              const sys = result?.vitals.bp_sys;
              const dia = result?.vitals.bp_dia;
              const selectedSYSRangeIndex = constants[
                "Blood Pressure"
              ].ranges[0].reduce((p, c, ci) => (sys > c.point ? ci : p), 0);
              const selectedDIARangeIndex = constants[
                "Blood Pressure"
              ].ranges[1].reduce((p, c, ci) => (dia > c.point ? ci : p), 0);
              return (
                <ListItemContainer
                  key="section-item-3"
                  label="Blood Pressure"
                  title={`Your Blood Pressure is ${constants["Blood Pressure"].ranges[0][selectedSYSRangeIndex].label}`}
                  desc={
                    constants["Blood Pressure"].info +
                    "\n\n" +
                    constants["Blood Pressure"].ranges[0][selectedSYSRangeIndex]
                      .desc
                  }
                >
                  <div className="py-3 flex items-center justify-between space-x-2">
                    <div className="flex-shrink-0 basis-1/4 text-center">
                      <div className="font-semibold">
                        <h4 className="inline text-secondary text-xl">{sys}</h4>
                        &nbsp;
                        <h5 className="inline text-primary/70 text-xxs">
                          {constants["Blood Pressure"].unit}
                        </h5>
                      </div>
                      <p className="text-primary/60 text-xxs font-medium">
                        Systolic
                      </p>
                    </div>
                    <Range
                      className="flex-grow"
                      ranges={constants["Blood Pressure"].ranges[0]}
                      selectedIndex={selectedSYSRangeIndex}
                    />
                  </div>
                  <div className="py-3 flex items-center justify-between space-x-2">
                    <div className="flex-shrink-0 basis-1/4 text-center">
                      <div className="font-semibold">
                        <h4 className="inline text-secondary text-xl">{dia}</h4>
                        &nbsp;
                        <h5 className="inline text-primary/70 text-xxs">
                          {constants["Blood Pressure"].unit}
                        </h5>
                      </div>
                      <p className="text-primary/60 text-xxs font-medium">
                        Diastolic
                      </p>
                    </div>
                    <Range
                      className="flex-grow"
                      ranges={constants["Blood Pressure"].ranges[1]}
                      selectedIndex={selectedDIARangeIndex}
                    />
                  </div>
                </ListItemContainer>
              );
            })()}
          </Tab.Panel>
          <Tab.Panel className="space-y-6">
            {[
              {
                label: "SDNN",
                value:
                  result?.metadata.heart_scores.sdnn !== "--"
                    ? Math.round(result?.metadata.heart_scores.sdnn)
                    : "--",
              },
              {
                label: "RMSSD",
                value:
                  result?.metadata.heart_scores.rmssd !== "--"
                    ? Math.round(result?.metadata.heart_scores.rmssd)
                    : "--",
              },
              {
                label: "PNN50",
                value: result?.metadata.heart_scores.pNN50_per,
              },
              {
                label: "Cardiac Output",
                value: result?.metadata.cardiovascular.cardiac_out,
              },
              {
                label: "Mean Arterial Pressure",
                value: result?.metadata.cardiovascular.map,
              },
              {
                label: "Heart Utilized",
                value: result?.metadata.heart_scores.heart_utilized,
              },
            ].map((item, index) => {
              const selectedRangeIndex = constants[item.label].ranges.reduce(
                (p, c, ci) => (item.value >= c.point ? ci : p),
                0
              );
              return (
                <ListItemContainer
                  key={`section-item-${index}`}
                  label={item.label}
                  title={`Your ${item.label} is ${
                    constants[item.label].ranges[selectedRangeIndex].label
                  }`}
                  desc={
                    constants[item.label].info +
                    "\n\n" +
                    constants[item.label].ranges[selectedRangeIndex].desc
                  }
                >
                  <div className="py-3 flex items-center justify-between space-x-2">
                    <div className="flex-shrink-0 basis-1/4 text-center font-semibold">
                      <h4 className="text-secondary text-xl">{item.value}</h4>
                      <h5 className="text-primary/70 text-xxs">
                        {constants[item.label].unit}
                      </h5>
                    </div>
                    <Range
                      className="flex-grow"
                      ranges={constants[item.label].ranges}
                      selectedIndex={
                        item.value !== "--" ? selectedRangeIndex : undefined
                      }
                    />
                  </div>
                </ListItemContainer>
              );
            })}
            <div className="grid grid-cols-2 gap-3">
              {[
                {
                  label: "Heart Rate Max",
                  value: result?.metadata.heart_scores.HRMax,
                  gradient: "from-[#FDEBAD] to-[#FFF5D4]",
                },
                {
                  label: "Heart Rate Reserve",
                  value: result?.metadata.heart_scores.HRR,
                  gradient: "from-[#E1CBF3]/[0.61] to-[#F4E9FE]/[0.79]",
                },
                {
                  label: "Target HR Range",
                  value: result?.metadata.heart_scores.THRR,
                  gradient: "from-[#BEEB9C]/[0.61] to-[#EFFCE4]",
                },
                {
                  label: "VO₂ Max",
                  value: result?.metadata.physiological_scores.vo2max,
                  gradient: "from-[#C2EAFB] to-[#E1F6FF]",
                },
              ].map((item, index) => (
                <button
                  type="button"
                  key={`section-smallitem-${index}`}
                  className={`px-3 py-4 rounded-2xl bg-gradient-to-r ${item.gradient} flex flex-col items-stretch justify-between text-left`}
                  onClick={() =>
                    setModalState({
                      visible: true,
                      title: item.label,
                      desc: constants[item.label].description,
                    })
                  }
                >
                  <div className="flex items-start justify-between space-x-1">
                    <h2 className="text-primary text-xs font-medium">
                      {item.label}
                    </h2>
                    <Info className="my-0.5 h-3 w-3 text-primary/60" />
                  </div>
                  <div className="mt-3 break-all font-semibold">
                    <h4 className="inline text-secondary text-xl">
                      {item.value}
                    </h4>
                    &nbsp;
                    <h5 className="inline text-primary/70 text-xxs">
                      {constants[item.label].unit}
                    </h5>
                  </div>
                </button>
              ))}
            </div>
          </Tab.Panel>
          <Tab.Panel className="space-y-6">
            {(() => {
              const bmi = result?.metadata.physiological_scores.bmi;
              const selectedBMIRangeIndex = constants.BMI.ranges.reduce(
                (p, c, ci) => (bmi > c.point ? ci : p),
                0
              );
              return (
                <ListItemContainer
                  label="BMI"
                  title={`Your BMI is ${constants.BMI.ranges[selectedBMIRangeIndex].label}`}
                  desc={
                    constants.BMI.info +
                    "\n\n" +
                    constants.BMI.ranges[selectedBMIRangeIndex].desc
                  }
                >
                  <div className="py-3 flex items-center justify-between space-x-2">
                    <div className="flex-shrink-0 basis-1/4 text-center font-semibold">
                      <h4 className="text-secondary text-xl">{bmi}</h4>
                      <h5 className="text-primary/70 text-xxs">
                        {constants.BMI.unit}
                      </h5>
                    </div>
                    <Range
                      className="flex-grow"
                      ranges={constants.BMI.ranges}
                      selectedIndex={selectedBMIRangeIndex}
                    />
                  </div>
                </ListItemContainer>
              );
            })()}
            <div>
              <div className="grid grid-cols-2 gap-3">
                {[
                  {
                    label: "Blood Volume",
                    value: result?.metadata.physiological_scores.bloodvolume,
                    gradient: "from-[#E1CBF3]/[0.61] to-[#F4E9FE]/[0.79]",
                  },
                  {
                    label: "Total Body Water",
                    value: result?.metadata.physiological_scores.tbw,
                    gradient: "from-[#C2EAFB] to-[#E1F6FF]",
                  },
                  ...(result?.metadata.physiological_scores.cal_carb !== "--" &&
                  result?.metadata.physiological_scores.cal_fat !== "--"
                    ? [
                        {
                          label: "Calorie from Carb",
                          value:
                            !(
                              result?.metadata.physiological_scores.cal_carb >=
                              0
                            ) ||
                            !(
                              result?.metadata.physiological_scores.cal_carb <=
                              100
                            )
                              ? "--"
                              : result.metadata.physiological_scores.cal_carb,
                          gradient: "from-[#BEEB9C]/[0.61] to-[#EFFCE4]",
                        },
                        {
                          label: "Calorie from Fat",
                          value:
                            !(
                              result?.metadata.physiological_scores.cal_fat >= 0
                            ) ||
                            !(
                              result?.metadata.physiological_scores.cal_fat <=
                              100
                            )
                              ? "--"
                              : result.metadata.physiological_scores.cal_fat,
                          gradient: "from-[#FDEBAD] to-[#FFF5D4]",
                        },
                      ]
                    : []),
                ].map((item, index) => (
                  <button
                    type="button"
                    key={`section-smallitem-${index}`}
                    className={`px-3 py-4 rounded-2xl bg-gradient-to-r ${item.gradient} flex flex-col items-stretch justify-between text-left`}
                    onClick={() =>
                      setModalState({
                        visible: true,
                        title: item.label,
                        desc: constants[item.label].description,
                      })
                    }
                  >
                    <div className="flex items-start justify-between space-x-1">
                      <h2 className="text-primary text-xs font-medium">
                        {item.label}
                      </h2>
                      <Info className="my-0.5 h-3 w-3 text-primary/60" />
                    </div>
                    <div className="mt-3 break-all font-semibold">
                      <h4 className="inline text-secondary text-xl">
                        {item.value}
                      </h4>
                      &nbsp;
                      <h5 className="inline text-primary/70 text-xxs">
                        {constants[item.label].unit}
                      </h5>
                    </div>
                  </button>
                ))}
              </div>
              <div className="mt-3 grid grid-cols-3 gap-3">
                {[
                  {
                    label: "Body Water %",
                    value: result?.metadata.physiological_scores.tbwp,
                  },
                  {
                    label: "Total Body Fat",
                    value: result?.metadata.physiological_scores.bodyfat,
                  },
                  {
                    label: "Body Weight",
                    value: result?.metadata.physiological_scores.weight,
                  },
                ].map((item, index) => (
                  <button
                    type="button"
                    key={`section-smallitem-${index}`}
                    className="px-3 py-4 rounded-2xl bg-[#EFFCE4] flex flex-col items-stretch justify-between text-left"
                    onClick={() =>
                      setModalState({
                        visible: true,
                        title: item.label,
                        desc: constants[item.label].description,
                      })
                    }
                  >
                    <div className="flex items-start justify-between space-x-1">
                      <h2 className="text-primary text-xs font-medium">
                        {item.label}
                      </h2>
                      <Info className="my-0.5 h-3 w-3 text-primary/60" />
                    </div>
                    <div className="mt-3 break-all font-semibold">
                      <h4 className="inline text-secondary text-xl">
                        {item.value}
                      </h4>
                      &nbsp;
                      <h5 className="inline text-primary/70 text-xxs">
                        {constants[item.label].unit}
                      </h5>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel className="space-y-6">
            {(() => {
              const stressLevel = result?.metadata.heart_scores.stress_index;
              const selectedRangeIndex = constants[
                "Stress Level"
              ].ranges.reduce(
                (p, c, ci) => (stressLevel > c.point ? ci : p),
                0
              );
              const selectedRange =
                constants["Stress Level"].ranges[selectedRangeIndex];
              let detailTitle = "";
              let detailDescription = "";
              if ((result?.metadata?.heart_scores?.rmssd ?? "--") !== "--") {
                if (result.metadata.heart_scores.rmssd < 65) {
                  if (
                    result.vitals.heart_rate > 100 ||
                    result.vitals.bp_sys > 130 ||
                    result.vitals.bp_dia > 90
                  ) {
                    detailTitle = "More Sympathetic Interactions";
                    detailDescription =
                      "Although HRV manifests as a function of your heart rate, it actually originates from your nervous system. Your autonomic nervous system, which controls the involuntary aspects of your physiology, has two branches, parasympathetic (deactivating) and sympathetic (activating).\n\nThe sympathetic nervous system (often called “fight or flight”) reflects responses to things like stress and exercise, and increases your heart rate and blood pressure.";
                  } else {
                    detailTitle = "More Parasympathetic Interactions";
                    detailDescription =
                      "Although HRV manifests as a function of your heart rate, it actually originates from your nervous system. Your autonomic nervous system, which controls the involuntary aspects of your physiology, has two branches, parasympathetic (deactivating) and sympathetic (activating)\n\nThe parasympathetic nervous system (often referred to as “rest and digest”) handles inputs from internal organs, like digestion or your fingernails and hair growing.";
                  }
                } else {
                  detailTitle = "Balanced ANS Interactions";
                  detailDescription =
                    "When you have high heart rate variability, it means that your body is responsive to both sets of inputs (parasympathetic and sympathetic). This is a sign that your nervous system is balanced, and that your body is very capable of adapting to its environment and performing at its best.";
                }
              } else {
                detailDescription =
                  "Not enought data to process. This happens when the signal strength of the scan is poor due to improper light or too much movements.";
              }
              return (
                <>
                  <div className="px-6 py-4 rounded-2xl bg-white shadow-box">
                    <div className="flex items-center justify-between space-x-4">
                      {selectedRange.icon}
                      <div className="flex-grow">
                        <h2 className="text-primary text-xs font-medium">
                          Stress Level
                        </h2>
                        <h4
                          className="mt-1 text-xxs font-medium uppercase"
                          style={{ color: selectedRange.color }}
                        >
                          {selectedRange.label}
                        </h4>
                      </div>
                      <div className="flex-shrink-0 text-right font-semibold">
                        <h4 className="text-secondary text-xl">
                          {stressLevel}
                        </h4>
                        <h5 className="text-primary/70 text-xxs">
                          {constants["Stress Level"].unit}
                        </h5>
                      </div>
                    </div>
                    <div className="mt-4">
                      <h5 className="text-primary text-xs font-medium">
                        Your Stress Level is {selectedRange.label}
                      </h5>
                      <p className="mt-1.5 text-primary/70 text-xxs">
                        {constants["Stress Level"].description}
                      </p>
                    </div>
                  </div>
                  <div className="p-6 rounded-xl bg-secondary/10 space-y-1.5">
                    {detailTitle.length > 0 && (
                      <h3 className="text-darkgray text-xs font-medium">
                        {detailTitle}
                      </h3>
                    )}
                    <p className="text-darkgray text-xxs text-justify">
                      {detailDescription}
                    </p>
                    <h5 className="text-darkgray text-xs text-center font-medium">
                      Autonomic Nervous Systems (ANS)
                    </h5>
                  </div>
                </>
              );
            })()}
          </Tab.Panel>
          <Tab.Panel className="space-y-6">
            {(() => {
              const wellbeingScore = result?.wellness_score;
              const selectedScoreRange = constants[
                "Wellbeing Score"
              ].ranges.reduce((p, c) => (wellbeingScore > c.point ? c : p));
              return (
                <>
                  <div className="flex flex-col items-center">
                    <ProgressCircle
                      size={120}
                      value={wellbeingScore}
                      color={selectedScoreRange.color}
                    />
                    <h2 className="mt-3 text-primary text-sm font-medium">
                      Wellbeing Score
                    </h2>
                    <h4
                      className="mt-1 text-xxs font-medium uppercase"
                      style={{ color: selectedScoreRange.color }}
                    >
                      {selectedScoreRange.label}
                    </h4>
                  </div>
                  <p className="text-darkgray text-xxs text-justify">
                    {selectedScoreRange.info}
                  </p>
                </>
              );
            })()}
          </Tab.Panel>
          <PDFDownloadLink
            className="flex items-center justify-between space-x-4 py-3 px-6 rounded-xl bg-secondary text-white text-sm font-medium"
            document={
              <PDFReport
                name={name}
                age={age}
                gender={gender.slice(0, 1).toUpperCase() + gender.slice(1)}
                height={height}
                weight={weight}
                result={{
                  timestamp: new Date(result?.entry_time ?? 0),
                  bp_sys: result?.vitals.bp_sys,
                  bp_dia: result?.vitals.bp_dia,
                  resp_rate: result?.vitals.resp_rate,
                  oxy_sat_prcnt: result?.vitals.oxy_sat_prcnt,
                  heart_rate: result?.vitals.heart_rate,
                  bmi: result?.metadata.physiological_scores.bmi,
                  sdnn:
                    result?.metadata.heart_scores.sdnn !== "--"
                      ? Math.round(result?.metadata.heart_scores.sdnn)
                      : "--",
                  rmssd:
                    result?.metadata.heart_scores.rmssd !== "--"
                      ? Math.round(result?.metadata.heart_scores.rmssd)
                      : "--",
                  pNN50_per: result?.metadata.heart_scores.pNN50_per,
                  cardiac_out: result?.metadata.cardiovascular.cardiac_out,
                  map: result?.metadata.cardiovascular.map,
                  heart_utilized: result?.metadata.heart_scores.heart_utilized,
                  HRMax: result?.metadata.heart_scores.HRMax,
                  HRR: result?.metadata.heart_scores.HRR,
                  THRR: result?.metadata.heart_scores.THRR,
                  vo2max: result?.metadata.physiological_scores.vo2max,
                }}
              />
            }
            fileName={`CarePlix-ScanResult-Report-${new Date(
              result?.entry_time ?? 0
            ).toISOString()}`}
          >
            {({ loading }) => (
              <>
                <span>
                  {loading ? "Loading document..." : "Download PDF Report"}
                </span>
                <Download className="flex-shrink-0 h-6 w-6" />
              </>
            )}
          </PDFDownloadLink>
          <div className="p-6 rounded-2xl flex flex-col items-center text-center bg-secondary text-white">
            <h4 className="text-xs font-medium">Disclaimer</h4>
            <p className="mt-2 text-xxs text-justify">
              For Investigational Use Only. Careplix is not a substitute for the
              clinical judgment of a healthcare professional. Careplix is
              intended to improve your awareness of general wellness. Careplix
              does not diagnose, treat, mitigate or prevent any disease,
              symptom, disorder or abnormal physical state. Consult with a
              health care professional or emergency services if you believe you
              may have a medical issue.
            </p>
            <Link
              className="mt-4 px-4 py-2 rounded-full bg-white text-primary text-xs font-medium"
              to="/"
              replace
            >
              Dashboard
            </Link>
          </div>
        </Tab.Panels>
      </Tab.Group>
      <Transition show={modalState.visible} as={Fragment}>
        <Dialog as={Fragment} onClose={closeModal}>
          <Transition.Child
            className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-100"
              leaveFrom="opacity-50 scale-50"
              leaveTo="opacity-0 scale-0"
            >
              <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                <Dialog.Title className="text-primary text-xs font-medium">
                  {modalState.title}
                </Dialog.Title>
                <Dialog.Description className="mt-2 text-primary/70 text-xxs">
                  {modalState.desc}
                </Dialog.Description>
                <button
                  className="mt-4 px-4 py-2 rounded-full bg-secondary text-white text-xs font-medium"
                  type="button"
                  onClick={closeModal}
                >
                  Close
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>
    </section>
  ) : (
    <Navigate to="/" replace />
  );
};

export default ScanResult;
