import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";
import { ReactComponent as Info } from "../assets/icons/Info.svg";

const Chart = ({ data = [], unit = "" }) => (
  <ResponsiveContainer width="100%" height={200}>
    <BarChart data={data} margin={{ right: 5, left: -25, top: 0, bottom: 0 }}>
      <XAxis
        dataKey="label"
        type="category"
        tickCount={7}
        tickLine={false}
        tickFormatter={(v) =>
          new Date(v).toLocaleString(undefined, { weekday: "short" })
        }
        stroke="rgba(25, 40, 82, 0.6)"
        fontFamily="Poppins"
        fontWeight="400"
        fontSize="10px"
      />
      <YAxis
        dataKey="value"
        type="number"
        stroke="rgba(25, 40, 82, 0.6)"
        fontFamily="Poppins"
        fontWeight="400"
        fontSize="10px"
      />
      <Bar dataKey="value" unit={unit} barSize={8} radius={[10, 10, 0, 0]}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color ?? "#2f98f1"} />
        ))}
      </Bar>
      <Tooltip
        cursor={false}
        content={({ active, payload }) =>
          active &&
          payload?.length > 0 && (
            <p className="px-1 py-0.5 rounded-md leading-none border border-secondary bg-white">
              <span
                className="text-secondary text-xs font-semibold"
                style={{ color: payload?.[0]?.payload?.color }}
              >
                {payload?.[0]?.value}
              </span>
              &nbsp;
              <span className="text-xxs text-black font-medium">
                {payload?.[0]?.unit}
              </span>
            </p>
          )
        }
      />
    </BarChart>
  </ResponsiveContainer>
);

const Graph = ({
  icon,
  label = "",
  chartData = [],
  legend = [],
  unit = "",
  defaultExpanded = false,
  viewInfo = () => {},
  historyDetail = {},
}) => (
  <Disclosure
    defaultOpen={defaultExpanded}
    as="div"
    className="px-6 py-4 rounded-2xl bg-white shadow-box"
  >
    <Disclosure.Button as="div" className="flex items-center space-x-4">
      {({ open }) => (
        <>
          <div className="flex-shrink-0 p-2.5 rounded-full bg-secondary text-white">
            {icon}
          </div>
          <div className="flex-grow flex items-center space-x-2">
            <h2 className="text-primary text-sm font-medium">{label}</h2>
            <button
              type="button"
              className="flex-shrink-0 h-4 w-4 text-primary/60"
              onClick={(e) => {
                e.stopPropagation();
                viewInfo();
              }}
            >
              <Info className="h-full w-full" />
            </button>
          </div>
          <Chevron
            className={`flex-shrink-0 h-4 w-4 text-primary/60 ${
              open ? "rotate-180" : "rotate-0"
            }`}
          />
        </>
      )}
    </Disclosure.Button>
    <Disclosure.Panel className="mt-6">
      {label === "Blood Pressure" ? (
        <>
          <Chart data={chartData[0]} unit={unit} />
          <Chart data={chartData[1]} unit={unit} />
        </>
      ) : (
        <Chart data={chartData} unit={unit} />
      )}
      <div className="mt-4 flex items-center justify-between space-x-1">
        <div className="flex flex-wrap items-center justify-start">
          {legend.map(({ label, color }, index) => (
            <div
              key={`legend-item-${index}`}
              className="mr-3 my-1 flex items-center space-x-1.5"
            >
              <div
                className="flex-shrink-0 h-3 w-3 rounded-full"
                style={{ backgroundColor: color }}
              />
              <h6 className="text-xxs leading-3 text-black">{label}</h6>
            </div>
          ))}
        </div>
        <Link
          className="flex-shrink-0 px-3 py-1.5 rounded-xl border border-darkgray/50 bg-white text-primary text-xs"
          to="/history"
          state={historyDetail}
        >
          View History
        </Link>
      </div>
    </Disclosure.Panel>
  </Disclosure>
);

export default Graph;
